import React from 'react';

import { ScrollArea, Spoiler, Text } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import type { SerializedError } from '@reduxjs/toolkit';
import { i18n } from 'visyn_core/i18n';

export function scoreDataErrorNotification(e: SerializedError, columnName: string) {
  const notificationId = `score-${columnName}`;
  showNotification({
    id: notificationId,
    color: 'red',
    title: <Text>{i18n.t('reprovisyn:dataTable.scoreError', { column: columnName })}</Text>,
    message: (
      <ScrollArea.Autosize mah={150}>
        <Spoiler maxHeight={120} showLabel={i18n.t('reprovisyn:messages.showMore')} hideLabel={i18n.t('reprovisyn:messages.hide')}>
          {e?.message}
        </Spoiler>
      </ScrollArea.Autosize>
    ),
  });
}
