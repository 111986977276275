import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import type { IColumnDump } from 'lineupjs';
import { v4 as uuidv4 } from 'uuid';
import type { IRow } from 'visyn_core/base';
import { pluginRegistry } from 'visyn_core/plugin';
import { EAggregateTypes, ESupportedPlotlyVis, isBarConfig, isCorrelationConfig, isHexbinConfig, isScatterConfig, isViolinConfig } from 'visyn_core/vis';

import type { EWorkbenchDirection, IOrdinoAppState, ISelectedMapping, IWorkbench } from './interfaces';
import { isCombinedColumn, isSupportType } from './interfaces';
import type { FilterItem, GlobalQuery, NamedIdSet } from './reprovisynApi';
import type { RootState } from './store';
import type { IOrdinoColumnDesc, IOrdinoColumnRow } from './types/column';
import { patchVisViewColumnIds } from './viewsReducer';
import type { EWorkbenchUtilsSidebarTab } from '../app/workbench/sidebar/constants';
import type { IScore } from '../tdp_core';
import { EXTENSION_POINT_TDP_SCORE_IMPL } from '../tdp_core';
import { toWorkbenchData } from './worker/workbench-data.worker';
import type { ModifiableColumnProps } from '../views/ranking/utils';
import { isModifiableColumnProp } from '../views/ranking/utils';

export const setWorkbenchDataAsync = createAsyncThunk<
  {
    workbenchIndex: number;
    fullData: IRow[];
    dataMap: Record<string, IRow>;
    columnData: Record<string, { [key: string]: IOrdinoColumnRow }>;
  },
  { workbenchIndex: number; data: IRow[] },
  {
    state: RootState;
  }
>('ordino/setWorkbenchDataAsync', async ({ workbenchIndex, data }, { getState }) => {
  const workbench = getState().ordino.workbenches[workbenchIndex]!;
  const initialColumns = workbench.columnDescs.filter((c) => c.initialRanking).map((c) => ({ ...c }));
  const result = toWorkbenchData({ rawData: data, columns: initialColumns });
  return { workbenchIndex, ...result };
});

export const addColumn = createAsyncThunk<
  { id: string; desc: IOrdinoColumnDesc; data: IOrdinoColumnRow[]; isLoading: boolean; filteredData: IOrdinoColumnRow[] },
  {
    workbenchIndex: number;
    id: string;
    desc: IOrdinoColumnDesc | null;
    // TODO: Type this properly!
    params: any; // { pluginId: string; workbenchIndex: number };
  },
  {
    state: RootState;
  }
>('ordino/addColumn', async ({ id, workbenchIndex, params }, { getState, requestId }) => {
  const { pluginId } = params;
  const pluginDesc = pluginRegistry.getPlugin(EXTENSION_POINT_TDP_SCORE_IMPL, pluginId);
  const plugin = await pluginDesc!.load();
  const scores: IScore | IScore[] = plugin.factory(params, pluginDesc);
  // TODO: Support multiple scores
  const [score] = Array.isArray(scores) ? scores : [scores];
  const columnDesc = score!.createDesc({}) as IOrdinoColumnDesc;
  const columnData = await score!.compute();
  const filteredData = getState().ordino.workbenches[workbenchIndex]!.data;
  const visibleSet = new Set(filteredData.map((d) => d.id));
  return {
    id,
    desc: columnDesc,
    data: columnData,
    filteredData: columnData.filter((d) => visibleSet.has(d.id)),
    isLoading: false,
  };
});

export const workbenchReducers = {
  addFirstWorkbench(
    state: IOrdinoAppState,
    action: PayloadAction<{
      workbench: IWorkbench;
      globalQuery?: GlobalQuery;
    }>,
  ) {
    state.focusWorkbenchIndex = 0;
    state.workbenches = [action.payload.workbench];
    state.globalQuery = action.payload.globalQuery;
    state.midTransition = false;
  },
  addWorkbench(state: IOrdinoAppState, action: PayloadAction<IWorkbench>) {
    state.midTransition = true;
    if (state.workbenches.length > action.payload.index) {
      state.workbenches.splice(action.payload.index);
    }
    state.workbenches.push(action.payload);
  },
  changeSelectedMappings(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; newMapping: ISelectedMapping }>) {
    const currentWorkbench = state.workbenches[action.payload.workbenchIndex];

    const { newMapping } = action.payload;
    if (
      !currentWorkbench.selectedMappings.find((m) => {
        const { entityId, columnSelection } = newMapping;
        return m.entityId === entityId && m.columnSelection === columnSelection;
      })
    ) {
      currentWorkbench.selectedMappings.push(newMapping);
    } else {
      currentWorkbench.selectedMappings = currentWorkbench.selectedMappings.filter((m) => {
        const { entityId, columnSelection } = newMapping;
        return !(m.entityId === entityId && m.columnSelection === columnSelection);
      });
    }
  },

  setCollapsed(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; collapsed: boolean }>) {
    state.workbenches[action.payload.workbenchIndex].collapsed = action.payload.collapsed;
  },
  setMidTransition(state: IOrdinoAppState) {
    state.midTransition = true;
    state.focusWorkbenchIndex -= 1;
  },
  setOpenSidebar(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; sidebar: EWorkbenchUtilsSidebarTab }>) {
    if (state.workbenches[action.payload.workbenchIndex]) {
      state.workbenches[action.payload.workbenchIndex].openSidebar = action.payload.sidebar;
    }
  },
  setCreateNextWorkbenchSidebarOpen(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; open: boolean }>) {
    state.workbenches[action.payload.workbenchIndex].createNextWorkbenchSidebarOpen = action.payload.open;
  },
  createColumnDescs(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; desc: IOrdinoColumnDesc[] }>) {
    const { workbenchIndex, desc } = action.payload;
    const columnDescsWithUniqueId = desc.map((d) => {
      const uniqueId = uuidv4();
      return { ...d, dataMap: null, uniqueId };
    }); // uniqueId is use by views to identify columns, id is use by LineUp to dump/restore columns

    const categoricalColumn = columnDescsWithUniqueId.find((c) => c.type === 'categorical' && c.initialRanking);
    const defaultVisConfig = {
      type: ESupportedPlotlyVis.BAR,
      catColumnSelected: categoricalColumn ? { id: categoricalColumn.uniqueId, name: categoricalColumn.label, description: null } : null,
      aggregateType: EAggregateTypes.COUNT,
    };
    // patch dataProviderDump with the uniqueIds
    const dataProviderDump = state.workbenches[workbenchIndex]?.primaryView?.parameters?.dataProviderDump;
    if (dataProviderDump) {
      const uniqueIdMap = new Map<string, any>(columnDescsWithUniqueId.map((c) => [c.column, c]));
      const toColumnDump = (colDump: IColumnDump) => {
        // support type columns have to be fully configured
        if (isSupportType(colDump?.desc)) {
          return colDump;
        }
        // combined columns
        if (colDump?.children?.length > 0) {
          const uniqueId = uuidv4();
          return { ...colDump, width: 200, id: uniqueId, desc: { ...colDump.desc, id: uniqueId }, children: colDump.children.map(toColumnDump) };
        }

        // simple column
        const colDesc = uniqueIdMap.get(colDump.column);
        const uniqueId = colDesc?.uniqueId;
        if (!uniqueId) {
          return null;
        }
        const descRef = `${colDesc.type}@${uniqueId}`;
        delete colDump.column; // allow overriding the initial column desc in the data provider dump from the landscape (e.g., to define the `groupCriteria` of a column)
        const fullColumnDump = { id: uniqueId, uniqueId, desc: descRef, ...colDump };
        return fullColumnDump;
      };
      const columnDump = (dataProviderDump.rankings[0].columns || []) as IColumnDump[];
      const columnDumpFinal = columnDump.map(toColumnDump).filter((d) => d) as IColumnDump[];
      dataProviderDump.rankings[0].columns = columnDumpFinal;
      if (dataProviderDump.rankings[0].groupColumns?.length) {
        const { groupColumns } = dataProviderDump.rankings[0];
        dataProviderDump.rankings[0].groupColumns = groupColumns.map((col) => uniqueIdMap.get(col)?.uniqueId);
      }
      if (dataProviderDump.rankings[0].sortCriteria?.length) {
        const { sortCriteria } = dataProviderDump.rankings[0];
        dataProviderDump.rankings[0].sortCriteria = sortCriteria.map((col) => ({ ...col, sortBy: uniqueIdMap.get(col.sortBy)?.uniqueId }));
      }
      if (dataProviderDump.rankings[0].groupSortCriteria?.length) {
        const { groupSortCriteria } = dataProviderDump.rankings[0];
        dataProviderDump.rankings[0].groupSortCriteria = groupSortCriteria.map((col) => ({ ...col, sortBy: uniqueIdMap.get(col.sortBy)?.uniqueId }));
      }
    }
    state.workbenches[workbenchIndex].views.forEach((view, i) => {
      const updatedView = patchVisViewColumnIds(columnDescsWithUniqueId, view);
      if (updatedView) {
        state.workbenches[workbenchIndex].views[i] = updatedView;
      }
    });
    state.workbenches[workbenchIndex].initialVisConfig = defaultVisConfig;
    state.workbenches[workbenchIndex].entityColumns = columnDescsWithUniqueId as IWorkbench['entityColumns'];
    state.workbenches[workbenchIndex].columnDescs = columnDescsWithUniqueId.filter((d) => d.initialRanking);
  },
  addColumnDesc(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; desc: IOrdinoColumnDesc }>) {
    const { workbenchIndex, desc } = action.payload;
    const workbench = state.workbenches[workbenchIndex]!;
    // don't add column descs if workbench doesn't exist
    if (!workbench) {
      return;
    }

    if (workbench.columnDescs.some((c) => c.uniqueId === desc.uniqueId)) {
      console.error('Column already exists in workbench');
      return;
    }
    if (desc.initialRanking) {
      const columnDataMap = Object.fromEntries(workbench.data.map((d) => [d.id, { id: d.id, val: d[desc.column] }]));
      workbench.columnDescs.push({ ...desc, dataMap: columnDataMap });
      return;
    }
    if (isCombinedColumn(desc)) {
      workbench.columnDescs.push(desc);
      return;
    }
    console.log('Column was not added. Column is not an initial ranking column or a combined column');
  },
  addColumnDescs(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; columnDescs: IOrdinoColumnDesc[] }>) {
    const { workbenchIndex, columnDescs } = action.payload;
    columnDescs.forEach((desc) => {
      workbenchReducers.addColumnDesc(state, { ...action, payload: { workbenchIndex, desc } });
    });
  },
  removeColumnDesc(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; uniqueId: string }>) {
    const { workbenchIndex, uniqueId } = action.payload;
    const columnIndex = state.workbenches[workbenchIndex].columnDescs.findIndex((c) => c.uniqueId === uniqueId);
    const { views } = state.workbenches[workbenchIndex];

    // clear column selection in vis configs if column is removed and is not a duplicate
    views.forEach((view, viewIndex) => {
      const visConfig = view?.parameters?.visConfig;
      if (visConfig) {
        if (
          (isScatterConfig(visConfig) || isCorrelationConfig(visConfig) || isViolinConfig(visConfig) || isBarConfig(visConfig) || isHexbinConfig(visConfig)) &&
          visConfig.numColumnsSelected?.some((c) => c.id === uniqueId)
        ) {
          state.workbenches[workbenchIndex].views[viewIndex].parameters.visConfig.numColumnsSelected = [];
        }

        if (isBarConfig(visConfig) && visConfig.catColumnSelected?.id === uniqueId) {
          state.workbenches[workbenchIndex].views[viewIndex].parameters.visConfig.catColumnSelected = null;
        }

        if (isViolinConfig(visConfig) && visConfig.catColumnSelected?.id === uniqueId) {
          state.workbenches[workbenchIndex].views[viewIndex].parameters.visConfig.catColumnSelected = null;
        }
      }
    });

    if (columnIndex !== -1) {
      state.workbenches[workbenchIndex].columnDescs.splice(columnIndex, 1);
    }
  },
  removeColumnDescs(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; uniqueIds: string[] }>) {
    const { workbenchIndex, uniqueIds } = action.payload;
    uniqueIds.forEach((uniqueId) => {
      workbenchReducers.removeColumnDesc(state, { ...action, payload: { workbenchIndex, uniqueId } });
    });
  },
  updateColumnDesc(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; uniqueId: string; modifiedColumnProps: ModifiableColumnProps }>) {
    const { workbenchIndex, uniqueId, modifiedColumnProps } = action.payload;
    const columnIndex = state.workbenches[workbenchIndex]!.columnDescs.findIndex((c) => c.uniqueId === uniqueId);
    if (columnIndex !== -1) {
      const columnDesc = state.workbenches[workbenchIndex]!.columnDescs[columnIndex]!;
      Object.keys(modifiedColumnProps).forEach((key) => {
        if (isModifiableColumnProp(key)) {
          if (modifiedColumnProps[key] !== undefined) {
            columnDesc[key] = modifiedColumnProps[key] as any;
          }
        }
      });
    }
  },
  batchUpdateColumnDescs(
    state: IOrdinoAppState,
    action: PayloadAction<{ workbenchIndex: number; updatedColumns: { id: string; modifiedColumnProps: ModifiableColumnProps }[] }>,
  ) {
    const { workbenchIndex, updatedColumns } = action.payload;
    updatedColumns.forEach((column) => {
      workbenchReducers.updateColumnDesc(state, {
        ...action,
        payload: { workbenchIndex, uniqueId: column.id, modifiedColumnProps: column.modifiedColumnProps },
      });
    });
  },

  setWorkbenchDirection(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; direction: EWorkbenchDirection }>) {
    state.workbenches[action.payload.workbenchIndex].viewDirection = action.payload.direction;
  },
  removeWorkbench(state: IOrdinoAppState, action: PayloadAction<{ index: number }>) {
    state.workbenches.splice(action.payload.index, state.workbenches.length);
  },
  removeWorkbenchOnEntityDelete(state: IOrdinoAppState, action: PayloadAction<{ entityId: string }>) {
    // on delete, remove any workbench of this entity, also remove any workbench following it
    const deletedEntityIdx = state.workbenches.findIndex((workbench) => workbench.entityId === action.payload.entityId);
    if (deletedEntityIdx !== -1) {
      // we want to go one index beyond the deleted entity, because also the previous workbench's view chooser needs to be wiped
      // (because we currently don't support reloading the view chooser, we delete the workbench instead)
      for (let inverseIndex = state.workbenches.length - 1; inverseIndex >= deletedEntityIdx - 1; inverseIndex--) {
        state.workbenches.splice(inverseIndex, 1);
      }
    }
  },
  replaceWorkbench(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; newWorkbench: IWorkbench }>) {
    state.workbenches.splice(action.payload.workbenchIndex);
    state.workbenches.push(action.payload.newWorkbench);
  },
  addSelection(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; newSelection: string[] }>) {
    const { workbenchIndex, newSelection } = action.payload;
    state.workbenches[workbenchIndex]!.selection = newSelection;
  },

  setWorkbenchFilter(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; filter: FilterItem[] }>) {
    const { workbenchIndex, filter } = action.payload;
    state.workbenches[workbenchIndex].filters = filter;
  },
  setWorkbenchNamedIdSet(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; namedIdSet: NamedIdSet | null }>) {
    const { workbenchIndex, namedIdSet } = action.payload;
    state.workbenches[workbenchIndex].namedIdSet = namedIdSet;
  },
  setWorkbenchDataLoading(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; isLoading: boolean }>) {
    const { workbenchIndex, isLoading } = action.payload;
    state.workbenches[workbenchIndex].isLoading = isLoading;
  },
  setWorkbenchData(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; rawData: IRow[] }>) {
    const { workbenchIndex, rawData } = action.payload;
    const columns = state.workbenches[workbenchIndex]!.columnDescs;
    const fullData = [] as IRow[];
    const dataMap: Record<string, IRow> = {};
    rawData.forEach((row) => {
      if (!dataMap[row.id]) {
        dataMap[row.id] = row;
        fullData.push(row);
      }
    });
    columns.forEach((column) => {
      if (!column.initialRanking) {
        return;
      }

      column.dataMap = {};

      fullData.map((row) => {
        const value = { id: row.id, val: row[column.column] };
        column.dataMap![row.id] = value;
        return value;
      });
    });
    state.workbenches[workbenchIndex]!.data = fullData;
    state.workbenches[workbenchIndex]!.dataMap = dataMap;
    state.workbenches[workbenchIndex]!.dataLength = fullData.length;
  },
  setWorkbenchFilteredData(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; filteredData: IRow[] }>) {
    const { workbenchIndex, filteredData } = action.payload;
    const workbench = state.workbenches[workbenchIndex];
    if (workbench && workbench.data.length) {
      workbench.filteredData = filteredData;
      workbench.isLoadingFilteredData = false;
      const filterLength = workbench.dataLength! - filteredData.length;
      workbench.filterLength = filterLength;
    }
  },
};
