import React from 'react';

import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ScrollArea, Spoiler, Text } from '@mantine/core';
import { showNotification, updateNotification } from '@mantine/notifications';
import { v4 as uuidv4 } from 'uuid';
import { i18n } from 'visyn_core/i18n';
import { pluginRegistry } from 'visyn_core/plugin';

import { reprovisynApi } from './reprovisynApi';
import { UPLOAD_TAG } from './reprovisynBaseApi';
import { visynApi } from './visynApi';
import { ANALYSIS_SESSIONS_TAG, UPLOADED_FILES } from './visynBaseApi';

const apiEnhancements: Parameters<typeof visynApi.enhanceEndpoints>[0] = {
  addTagTypes: [UPLOAD_TAG],
  endpoints: {
    getUploadedFilesListMetadataApiFilesListMetadataGet: {
      providesTags: (result) => (result ? [{ type: UPLOADED_FILES, id: 'List' }] : []),
    },
    deleteUploadApiFilesUuidDelete: {
      invalidatesTags: () => [{ type: UPLOADED_FILES, id: 'List' }],
      onQueryStarted: async (args, { queryFulfilled }) => {
        const notificationId = uuidv4();
        showNotification({
          id: notificationId,
          loading: true,
          color: 'dvGray',
          title: (
            <Text data-testid="ordino-delete-uploaded-file-notification-progress">
              {i18n.t('reprovisyn:notifications.deletingWithTypeWithName', {
                item: args.uuid,
                type: i18n.t('reprovisyn:datasets.file'),
                interpolation: { escapeValue: false },
              })}
            </Text>
          ),
          message: '',
          autoClose: false,
          withCloseButton: false,
        });
        queryFulfilled
          .then((data) =>
            updateNotification({
              id: notificationId,
              loading: false,
              icon: <FontAwesomeIcon icon={faCheck} />,
              color: 'teal',
              title: (
                <Text data-testid="ordino-delete-uploaded-file-notification-success">
                  {i18n.t('reprovisyn:notifications.deleteSuccess', {
                    type: i18n.t('reprovisyn:datasets.file'),
                    name: args.uuid,
                    interpolation: { escapeValue: false },
                  })}
                </Text>
              ),
              message: '',
              autoClose: 5000,
            }),
          )
          .catch(({ error }) => {
            updateNotification({
              id: notificationId,
              loading: false,
              color: 'red',
              title: (
                <Text data-testid="ordino-delete-uploaded-file-error">
                  {i18n.t('reprovisyn:notifications.deleteError', {
                    type: i18n.t('reprovisyn:datasets.file'),
                    name: args.uuid,
                    interpolation: { escapeValue: false },
                  })}
                </Text>
              ),
              message: (
                <ScrollArea.Autosize mah={150}>
                  <Spoiler maxHeight={120} showLabel={i18n.t('reprovisyn:messages.showMore')} hideLabel={i18n.t('reprovisyn:messages.hide')}>
                    {error?.data.detail || error?.message}
                  </Spoiler>
                </ScrollArea.Autosize>
              ),
            });
          });
      },
    },
    getAnalysisSessionsListApiAnalysisSessionsListGet: {
      providesTags: (result) => (result ? [{ type: ANALYSIS_SESSIONS_TAG, id: 'List' }] : []),
    },
    getAnalysisSessionApiAnalysisSessionsIdGet: {
      providesTags: (result, error, { id }) => [{ type: ANALYSIS_SESSIONS_TAG, id }],
    },
    updateAnalysisSessionApiAnalysisSessionsIdPut: {
      invalidatesTags: (result, error, { id }) => [
        { type: ANALYSIS_SESSIONS_TAG, id },
        { type: ANALYSIS_SESSIONS_TAG, id: 'List' },
      ],
      onQueryStarted: async (args, { queryFulfilled }) => {
        const notificationId = uuidv4();

        showNotification({
          id: notificationId,
          loading: true,
          color: 'gray',
          title: (
            <Text>
              {i18n.t('reprovisyn:notifications.saving', {
                type: 'session',
                name: args.analysisSessionUpdate.name,
                interpolation: { escapeValue: false },
              })}
            </Text>
          ),
          message: '',
          autoClose: false,
          withCloseButton: false,
        });

        queryFulfilled
          .then((data) =>
            updateNotification({
              id: notificationId,
              loading: false,
              icon: <FontAwesomeIcon icon={faCheck} />,
              color: 'teal',
              title: (
                <Text>
                  {i18n.t('reprovisyn:notifications.save', {
                    type: 'Session',
                    name: args.analysisSessionUpdate.name,
                    interpolation: { escapeValue: false },
                  })}
                </Text>
              ),
              message: '',
              autoClose: 5000,
            }),
          )
          .catch(({ error }) => {
            updateNotification({
              id: notificationId,
              loading: false,
              mah: 500,
              color: 'red',
              title: (
                <Text>
                  {i18n.t('reprovisyn:notifications.errorSaving', {
                    type: 'session',
                    name: args?.analysisSessionUpdate.name,
                    interpolation: { escapeValue: false },
                  })}
                </Text>
              ),
              message: (
                <ScrollArea.Autosize mah={150}>
                  <Spoiler maxHeight={100} showLabel={i18n.t('reprovisyn:messages.showMore')} hideLabel={i18n.t('reprovisyn:messages.hide')}>
                    {error?.data.detail || error?.message}
                  </Spoiler>
                </ScrollArea.Autosize>
              ),
              autoClose: false,
            });
          });
      },
    },
    createAnalysisSessionApiAnalysisSessionsCreatePost: {
      invalidatesTags: [{ type: ANALYSIS_SESSIONS_TAG, id: 'List' }],
      onQueryStarted: async (args, { queryFulfilled }) => {
        const notificationId = uuidv4();
        showNotification({
          id: notificationId,
          loading: true,
          color: 'gray',
          title: (
            <Text>
              {i18n.t('reprovisyn:notifications.saving', {
                type: 'session',
                name: args.analysisSessionCreate.name,
                interpolation: { escapeValue: false },
              })}
            </Text>
          ),
          message: '',
          autoClose: false,
          withCloseButton: false,
        });
        queryFulfilled
          .then((data) =>
            updateNotification({
              id: notificationId,
              loading: false,
              icon: <FontAwesomeIcon icon={faCheck} />,
              color: 'teal',
              title: (
                <Text>
                  {i18n.t('reprovisyn:notifications.save', {
                    type: 'Session',
                    name: args.analysisSessionCreate.name,
                    interpolation: { escapeValue: false },
                  })}
                </Text>
              ),
              message: '',
              autoClose: 5000,
            }),
          )
          .catch(({ error }) => {
            updateNotification({
              id: notificationId,
              loading: false,
              mah: 500,
              color: 'red',
              title: (
                <Text>
                  {i18n.t('reprovisyn:notifications.errorSaving', {
                    type: 'session',
                    name: args?.analysisSessionCreate.name,
                    interpolation: { escapeValue: false },
                  })}
                </Text>
              ),
              message: (
                <ScrollArea.Autosize mah={150}>
                  <Spoiler maxHeight={100} showLabel={i18n.t('reprovisyn:messages.showMore')} hideLabel={i18n.t('reprovisyn:messages.hide')}>
                    {error?.data.detail || error?.message}
                  </Spoiler>
                </ScrollArea.Autosize>
              ),
              autoClose: false,
            });
          });
      },
    },
    deleteAnalysisSessionApiAnalysisSessionsIdDelete: {
      invalidatesTags: [{ type: ANALYSIS_SESSIONS_TAG, id: 'List' }],
      onQueryStarted: async (args, { queryFulfilled }) => {
        const notificationId = uuidv4();
        showNotification({
          id: notificationId,
          loading: true,
          color: 'gray',
          title: (
            <Text>
              {i18n.t('reprovisyn:notifications.deletingWithTypeWithName', { type: 'session', item: args.id, interpolation: { escapeValue: false } })}
            </Text>
          ),
          message: '',
          autoClose: false,
          withCloseButton: false,
        });
        queryFulfilled
          .then((data) =>
            updateNotification({
              id: notificationId,
              loading: false,
              icon: <FontAwesomeIcon icon={faCheck} />,
              color: 'teal',
              title: (
                <Text>
                  {i18n.t('reprovisyn:notifications.deleteSuccess', {
                    type: 'Session',
                    name: args.id,
                    interpolation: { escapeValue: false },
                  })}
                </Text>
              ),
              message: '',
              autoClose: 5000,
            }),
          )
          .catch(({ error }) => {
            updateNotification({
              id: notificationId,
              loading: false,
              mah: 500,
              color: 'red',
              title: (
                <Text>
                  {i18n.t('reprovisyn:notifications.deleteError', {
                    type: 'session',
                    name: args.id,
                    interpolation: { escapeValue: false },
                  })}
                </Text>
              ),
              message: (
                <ScrollArea.Autosize mah={150}>
                  <Spoiler maxHeight={100} showLabel={i18n.t('reprovisyn:messages.showMore')} hideLabel={i18n.t('reprovisyn:messages.hide')}>
                    {error?.data.detail || error?.message}
                  </Spoiler>
                </ScrollArea.Autosize>
              ),
              autoClose: false,
            });
          });
      },
    },
    entityPostApiUploadUuidPost: {
      invalidatesTags: [{ type: UPLOAD_TAG, id: 'List' }],
    },
    entityPutApiUploadUuidPut: {
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => {
          dispatch(reprovisynApi.util.invalidateTags([UPLOAD_TAG]));
        });
      },
      invalidatesTags: [{ type: UPLOAD_TAG, id: 'List' }],
    },
    entityDeleteApiUploadUuidDelete: {
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        const notificationId = uuidv4();
        showNotification({
          id: notificationId,
          loading: true,
          color: 'gray',
          title: (
            <Text data-testid="ordino-delete-uploaded-dataset-notification-progress">
              {i18n.t('reprovisyn:notifications.deletingWithType', { type: i18n.t('reprovisyn:datasets.dataset'), interpolation: { escapeValue: false } })}
            </Text>
          ),
          message: '',
          autoClose: false,
          withCloseButton: false,
        });
        queryFulfilled
          .then(({ data: entityMeta }) => {
            dispatch(reprovisynApi.util.invalidateTags([UPLOAD_TAG]));
            pluginRegistry.removePlugins((desc) => desc.id.includes(args.uuid));
            updateNotification({
              id: notificationId,
              loading: false,
              icon: <FontAwesomeIcon icon={faCheck} />,
              color: 'teal',
              title: (
                <Text data-testid="ordino-delete-uploaded-dataset-notification-success">
                  {i18n.t('reprovisyn:notifications.deleteSuccess', {
                    type: i18n.t('reprovisyn:datasets.dataset'),
                    interpolation: { escapeValue: false },
                  })}
                </Text>
              ),
              message: '',
              autoClose: 5000,
            });
          })
          .catch(({ error }) => {
            updateNotification({
              id: notificationId,
              loading: false,
              color: 'red',
              title: (
                <Text data-testid="ordino-delete-uploaded-dataset-notification-error">
                  {i18n.t('reprovisyn:notifications.deleteError', {
                    type: i18n.t('reprovisyn:datasets.dataset'),
                    name: args.uuid,
                    interpolation: { escapeValue: false },
                  })}
                </Text>
              ),
              message: error?.data.detail || error?.message,
            });
          });
      },
      invalidatesTags: [{ type: UPLOAD_TAG, id: 'List' }],
    },
  },
};

export function addVisynApiEnhancements() {
  [apiEnhancements].forEach((e) => visynApi.enhanceEndpoints(e));
}
