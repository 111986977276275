import React, { useEffect, useState } from 'react';

import { useLocalStorage } from '@mantine/hooks';
import { BlurredOverlay } from 'visyn_core/components';
import { i18n } from 'visyn_core/i18n';

import { useTabs } from './useTabs';
import { LS_SHOW_BANNER_KEY } from '../../app/constants';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { EStartMenuMode, EStartMenuTab, setActiveTab } from '../../store/menuSlice';

export function StartMenuTabWrapper() {
  const dispatch = useAppDispatch();
  const appConfig = useAppSelector((state) => state.app.uiConfig);
  const tabs = useTabs({ appConfig });
  const { activeTab, mode } = useAppSelector((state) => state.menu);
  const [showBanner] = useLocalStorage({ key: LS_SHOW_BANNER_KEY });
  const [loadedTabs, setLoadedTabs] = useState<EStartMenuTab[]>([]);

  useEffect(() => {
    if (activeTab === EStartMenuTab.NONE) {
      return;
    }

    if (!loadedTabs.includes(activeTab)) {
      setLoadedTabs([...loadedTabs, activeTab]);
    }
  }, [loadedTabs, activeTab]);

  return (
    <div
      id="ordino-start-menu"
      className={`ordino-start-menu tab-content ${activeTab === EStartMenuTab.NONE ? 'd-none' : 'ordino-start-menu-open'} ${mode === EStartMenuMode.OVERLAY ? 'ordino-start-menu-overlay' : ''} ${showBanner ? 'ordino-start-menu-banner' : ''}`}
    >
      {tabs.map(({ id, Tab }) => (
        <div
          className={`tab-pane fade ${activeTab === id ? 'active show' : ''} ${mode === EStartMenuMode.START ? 'pt-5' : ''}`}
          key={id}
          id={id}
          role="tabpanel"
          aria-labelledby={`${id}-tab`}
        >
          {mode === EStartMenuMode.OVERLAY && (
            <div className="container-fluid">
              <div className="row">
                <div className="col position-relative d-flex justify-content-end">
                  <button
                    data-testid="ordino-close-view"
                    type="button"
                    className="btn-close"
                    onClick={() => {
                      dispatch(setActiveTab(EStartMenuTab.NONE));
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {activeTab === id || loadedTabs.includes(id) ? (
            <React.Suspense fallback={<BlurredOverlay loading loadingText={i18n.t('reprovisyn:app.loading')} />}>
              <Tab />
            </React.Suspense>
          ) : null}
        </div>
      ))}
    </div>
  );
}
