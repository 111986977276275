import type { IOrdinoAppState } from '../interfaces';
import createTransform from '../persist/createTransform';

export const workbenchesPersistTransform = createTransform<IOrdinoAppState[keyof IOrdinoAppState]>(
  (inboundState, key) => {
    // ignore the workbench data when saving the state
    if (key === 'workbenches') {
      const v = (inboundState as IOrdinoAppState['workbenches']).map((workbench) => ({
        ...workbench,
        data: [],
        filteredData: [],
        dataMap: {},
        columnDescs: workbench.columnDescs
          .filter((c) => !c.isCombinedColumn)
          .map((columnDesc) => ({
            ...columnDesc,
            dataMap: {},
          })),
        isLoading: false,
        isLoadingFilteredData: false,
        dataLength: undefined,
        openSidebar: null,
      }));

      return v;
    }
    return inboundState;
  },
  (outboundState, key) => {
    // do the same filtering on the outbound state
    if (key === 'workbenches') {
      return (outboundState as IOrdinoAppState['workbenches']).map((workbench) => ({
        ...workbench,
        isLoading: false,
        isLoadingFilteredData: false,
        // filter out the selection adapter columns that might have been saved in an older session. All new sessions do not use this property anymore
        columnDescs: workbench.columnDescs.filter((columnDesc) => !(<any>columnDesc).isSelectionAdapterColumn),
      }));
    }
    return outboundState;
  },
  { whitelist: ['workbenches'] },
);
