import * as React from 'react';

import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons/faTriangleExclamation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Text } from '@mantine/core';

export function WarningMessage({ warning, dataTestId = 'ordino-warning-message' }: { warning: string | null; dataTestId?: string }) {
  return warning ? (
    <Alert px="md" icon={<FontAwesomeIcon icon={faTriangleExclamation} />} color="yellow" data-testid={dataTestId}>
      <Text>{warning}</Text>
    </Alert>
  ) : null;
}
