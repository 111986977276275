import { reprovisynApi as api } from './reprovisynBaseApi';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiV1ReprovisynAppConfigBanner: build.query<GetApiV1ReprovisynAppConfigBannerApiResponse, GetApiV1ReprovisynAppConfigBannerApiArg>({
      query: () => ({ url: `/api/v1/reprovisyn/app_config/banner` }),
    }),
    getApiV1ReprovisynAppConfigCustomization: build.query<GetApiV1ReprovisynAppConfigCustomizationApiResponse, GetApiV1ReprovisynAppConfigCustomizationApiArg>({
      query: () => ({ url: `/api/v1/reprovisyn/app_config/customization` }),
    }),
    getApiV1ReprovisynAppConfigUi: build.query<GetApiV1ReprovisynAppConfigUiApiResponse, GetApiV1ReprovisynAppConfigUiApiArg>({
      query: () => ({ url: `/api/v1/reprovisyn/app_config/ui` }),
    }),
    getApiV1ReprovisynAppConfigUploadConfig: build.query<GetApiV1ReprovisynAppConfigUploadConfigApiResponse, GetApiV1ReprovisynAppConfigUploadConfigApiArg>({
      query: () => ({ url: `/api/v1/reprovisyn/app_config/upload_config` }),
    }),
    getApiV1ReprovisynAppConfigVersion: build.query<GetApiV1ReprovisynAppConfigVersionApiResponse, GetApiV1ReprovisynAppConfigVersionApiArg>({
      query: () => ({ url: `/api/v1/reprovisyn/app_config/version` }),
    }),
    getApiV1ReprovisynCampaignTypes: build.query<GetApiV1ReprovisynCampaignTypesApiResponse, GetApiV1ReprovisynCampaignTypesApiArg>({
      query: () => ({ url: `/api/v1/reprovisyn/campaign_types` }),
    }),
    getApiV1ReprovisynClearCache: build.query<GetApiV1ReprovisynClearCacheApiResponse, GetApiV1ReprovisynClearCacheApiArg>({
      query: () => ({ url: `/api/v1/reprovisyn/clear_cache` }),
    }),
    getApiV1ReprovisynEntities: build.query<GetApiV1ReprovisynEntitiesApiResponse, GetApiV1ReprovisynEntitiesApiArg>({
      query: () => ({ url: `/api/v1/reprovisyn/entities` }),
    }),
    postApiV1ReprovisynEntitiesCountMulti: build.query<PostApiV1ReprovisynEntitiesCountMultiApiResponse, PostApiV1ReprovisynEntitiesCountMultiApiArg>({
      query: (queryArg) => ({ url: `/api/v1/reprovisyn/entities/count_multi`, method: 'POST', body: queryArg.entityCountArgs }),
    }),
    postApiV1ReprovisynEntitiesByEntityIdCheckDataAvailability: build.mutation<
      PostApiV1ReprovisynEntitiesByEntityIdCheckDataAvailabilityApiResponse,
      PostApiV1ReprovisynEntitiesByEntityIdCheckDataAvailabilityApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/reprovisyn/entities/${queryArg.entityId}/check-data-availability`,
        method: 'POST',
        body: queryArg.dataTypeEntityDataAvailability,
      }),
    }),
    getApiV1ReprovisynEntitiesByEntityIdCount: build.query<
      GetApiV1ReprovisynEntitiesByEntityIdCountApiResponse,
      GetApiV1ReprovisynEntitiesByEntityIdCountApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/reprovisyn/entities/${queryArg.entityId}/count` }),
    }),
    postApiV1ReprovisynEntitiesByEntityIdCount: build.query<
      PostApiV1ReprovisynEntitiesByEntityIdCountApiResponse,
      PostApiV1ReprovisynEntitiesByEntityIdCountApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/reprovisyn/entities/${queryArg.entityId}/count`, method: 'POST', body: queryArg.countArgs }),
    }),
    getApiV1ReprovisynEntitiesByEntityIdData: build.query<GetApiV1ReprovisynEntitiesByEntityIdDataApiResponse, GetApiV1ReprovisynEntitiesByEntityIdDataApiArg>({
      query: (queryArg) => ({ url: `/api/v1/reprovisyn/entities/${queryArg.entityId}/data`, body: queryArg.dataArgs }),
    }),
    postApiV1ReprovisynEntitiesByEntityIdData: build.query<
      PostApiV1ReprovisynEntitiesByEntityIdDataApiResponse,
      PostApiV1ReprovisynEntitiesByEntityIdDataApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/reprovisyn/entities/${queryArg.entityId}/data`, method: 'POST', body: queryArg.dataArgs }),
    }),
    postApiV1ReprovisynEntitiesByEntityIdIsCampaignable: build.query<
      PostApiV1ReprovisynEntitiesByEntityIdIsCampaignableApiResponse,
      PostApiV1ReprovisynEntitiesByEntityIdIsCampaignableApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/reprovisyn/entities/${queryArg.entityId}/is_campaignable`, method: 'POST', body: queryArg.campaignableArgs }),
    }),
    getApiV1ReprovisynEntitiesByEntityIdMetadata: build.query<
      GetApiV1ReprovisynEntitiesByEntityIdMetadataApiResponse,
      GetApiV1ReprovisynEntitiesByEntityIdMetadataApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/reprovisyn/entities/${queryArg.entityId}/metadata` }),
    }),
    postApiV1ReprovisynEntitiesByEntityIdMetadata: build.query<
      PostApiV1ReprovisynEntitiesByEntityIdMetadataApiResponse,
      PostApiV1ReprovisynEntitiesByEntityIdMetadataApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/reprovisyn/entities/${queryArg.entityId}/metadata`, method: 'POST', body: queryArg.entityArgs }),
    }),
    postApiV1ReprovisynEntitiesByEntityIdSearch: build.query<
      PostApiV1ReprovisynEntitiesByEntityIdSearchApiResponse,
      PostApiV1ReprovisynEntitiesByEntityIdSearchApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/reprovisyn/entities/${queryArg.entityId}/search`, method: 'POST', body: queryArg.searchArgs }),
    }),
    postApiV1ReprovisynEntityMappingScoreByFromIdAndToId: build.query<
      PostApiV1ReprovisynEntityMappingScoreByFromIdAndToIdApiResponse,
      PostApiV1ReprovisynEntityMappingScoreByFromIdAndToIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/reprovisyn/entity_mapping_score/${queryArg.fromId}/${queryArg.toId}`,
        method: 'POST',
        body: queryArg.entityMappingDataArgs,
      }),
    }),
    getApiV1ReprovisynEntityMappingsByFromId: build.query<GetApiV1ReprovisynEntityMappingsByFromIdApiResponse, GetApiV1ReprovisynEntityMappingsByFromIdApiArg>({
      query: (queryArg) => ({ url: `/api/v1/reprovisyn/entity_mappings/${queryArg.fromId}` }),
    }),
    postApiV1ReprovisynEntityMappingsMulti: build.query<PostApiV1ReprovisynEntityMappingsMultiApiResponse, PostApiV1ReprovisynEntityMappingsMultiApiArg>({
      query: (queryArg) => ({ url: `/api/v1/reprovisyn/entity_mappings_multi`, method: 'POST', body: queryArg.mappingIds }),
    }),
    getApiV1ReprovisynFileByUuidMetadata: build.query<GetApiV1ReprovisynFileByUuidMetadataApiResponse, GetApiV1ReprovisynFileByUuidMetadataApiArg>({
      query: (queryArg) => ({ url: `/api/v1/reprovisyn/file/${queryArg.uuid}/metadata` }),
    }),
    getApiV1ReprovisynIdtypeMappings: build.query<GetApiV1ReprovisynIdtypeMappingsApiResponse, GetApiV1ReprovisynIdtypeMappingsApiArg>({
      query: () => ({ url: `/api/v1/reprovisyn/idtype_mappings` }),
    }),
    getApiV1ReprovisynIdtypeMappingsByFromId: build.query<GetApiV1ReprovisynIdtypeMappingsByFromIdApiResponse, GetApiV1ReprovisynIdtypeMappingsByFromIdApiArg>({
      query: (queryArg) => ({ url: `/api/v1/reprovisyn/idtype_mappings/${queryArg.fromId}` }),
    }),
    postApiV1ReprovisynIdtypeMappingsByFromIdAndToId: build.query<
      PostApiV1ReprovisynIdtypeMappingsByFromIdAndToIdApiResponse,
      PostApiV1ReprovisynIdtypeMappingsByFromIdAndToIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/reprovisyn/idtype_mappings/${queryArg.fromId}/${queryArg.toId}`, method: 'POST', body: queryArg.mappingIds }),
    }),
    getApiV1ReprovisynIdtypes: build.query<GetApiV1ReprovisynIdtypesApiResponse, GetApiV1ReprovisynIdtypesApiArg>({
      query: () => ({ url: `/api/v1/reprovisyn/idtypes` }),
    }),
    getApiV1ReprovisynNamedIdSets: build.query<GetApiV1ReprovisynNamedIdSetsApiResponse, GetApiV1ReprovisynNamedIdSetsApiArg>({
      query: () => ({ url: `/api/v1/reprovisyn/named_id_sets` }),
    }),
    postApiV1ReprovisynNamedIdSets: build.query<PostApiV1ReprovisynNamedIdSetsApiResponse, PostApiV1ReprovisynNamedIdSetsApiArg>({
      query: (queryArg) => ({ url: `/api/v1/reprovisyn/named_id_sets`, method: 'POST', body: queryArg.namedIdSetFilterArgs }),
    }),
    postApiV1ReprovisynNamedIdSetsCreate: build.mutation<PostApiV1ReprovisynNamedIdSetsCreateApiResponse, PostApiV1ReprovisynNamedIdSetsCreateApiArg>({
      query: (queryArg) => ({ url: `/api/v1/reprovisyn/named_id_sets/create`, method: 'POST', body: queryArg.namedIdSetCreateArgs }),
    }),
    deleteApiV1ReprovisynNamedIdSetsBySetId: build.mutation<DeleteApiV1ReprovisynNamedIdSetsBySetIdApiResponse, DeleteApiV1ReprovisynNamedIdSetsBySetIdApiArg>({
      query: (queryArg) => ({ url: `/api/v1/reprovisyn/named_id_sets/${queryArg.setId}`, method: 'DELETE' }),
    }),
    putApiV1ReprovisynNamedIdSetsBySetId: build.mutation<PutApiV1ReprovisynNamedIdSetsBySetIdApiResponse, PutApiV1ReprovisynNamedIdSetsBySetIdApiArg>({
      query: (queryArg) => ({ url: `/api/v1/reprovisyn/named_id_sets/${queryArg.setId}`, method: 'PUT', body: queryArg.namedIdSetUpdateArgs }),
    }),
    getApiV1ReprovisynNamedIdSetsBySetIdData: build.query<GetApiV1ReprovisynNamedIdSetsBySetIdDataApiResponse, GetApiV1ReprovisynNamedIdSetsBySetIdDataApiArg>({
      query: (queryArg) => ({ url: `/api/v1/reprovisyn/named_id_sets/${queryArg.setId}/data` }),
    }),
    getApiV1ReprovisynNamedIdSetsBySetIdMetadata: build.query<
      GetApiV1ReprovisynNamedIdSetsBySetIdMetadataApiResponse,
      GetApiV1ReprovisynNamedIdSetsBySetIdMetadataApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/reprovisyn/named_id_sets/${queryArg.setId}/metadata` }),
    }),
    getApiV1ReprovisynProxyViews: build.query<GetApiV1ReprovisynProxyViewsApiResponse, GetApiV1ReprovisynProxyViewsApiArg>({
      query: () => ({ url: `/api/v1/reprovisyn/proxy_views` }),
    }),
    getApiV1ReprovisynRelations: build.query<GetApiV1ReprovisynRelationsApiResponse, GetApiV1ReprovisynRelationsApiArg>({
      query: () => ({ url: `/api/v1/reprovisyn/relations` }),
    }),
    postApiV1ReprovisynRelations: build.query<PostApiV1ReprovisynRelationsApiResponse, PostApiV1ReprovisynRelationsApiArg>({
      query: (queryArg) => ({ url: `/api/v1/reprovisyn/relations`, method: 'POST', body: queryArg.relationsArgs }),
    }),
    postApiV1ReprovisynRelationsData: build.query<PostApiV1ReprovisynRelationsDataApiResponse, PostApiV1ReprovisynRelationsDataApiArg>({
      query: (queryArg) => ({ url: `/api/v1/reprovisyn/relations/data`, method: 'POST', body: queryArg.mappingRelationArgs }),
    }),
    getApiV1ReprovisynTestDatabaseConnections: build.query<
      GetApiV1ReprovisynTestDatabaseConnectionsApiResponse,
      GetApiV1ReprovisynTestDatabaseConnectionsApiArg
    >({
      query: () => ({ url: `/api/v1/reprovisyn/test_database_connections` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as reprovisynApi };
export type GetApiV1ReprovisynAppConfigBannerApiResponse = /** status 200 undefined */ Banner;
export type GetApiV1ReprovisynAppConfigBannerApiArg = void;
export type GetApiV1ReprovisynAppConfigCustomizationApiResponse = /** status 200 undefined */ Customization;
export type GetApiV1ReprovisynAppConfigCustomizationApiArg = void;
export type GetApiV1ReprovisynAppConfigUiApiResponse = /** status 200 undefined */ UiConfig;
export type GetApiV1ReprovisynAppConfigUiApiArg = void;
export type GetApiV1ReprovisynAppConfigUploadConfigApiResponse = /** status 200 undefined */ UploadFileConfig;
export type GetApiV1ReprovisynAppConfigUploadConfigApiArg = void;
export type GetApiV1ReprovisynAppConfigVersionApiResponse = /** status 200 undefined */ VersionResponse;
export type GetApiV1ReprovisynAppConfigVersionApiArg = void;
export type GetApiV1ReprovisynCampaignTypesApiResponse = /** status 200 undefined */ CampaignType[];
export type GetApiV1ReprovisynCampaignTypesApiArg = void;
export type GetApiV1ReprovisynClearCacheApiResponse = /** status 200 undefined */ MessageResponse;
export type GetApiV1ReprovisynClearCacheApiArg = void;
export type GetApiV1ReprovisynEntitiesApiResponse = /** status 200 undefined */ (EntityMeta | UploadedEntityMeta)[];
export type GetApiV1ReprovisynEntitiesApiArg = void;
export type PostApiV1ReprovisynEntitiesCountMultiApiResponse = /** status 200 undefined */ {
  [key: string]: number;
};
export type PostApiV1ReprovisynEntitiesCountMultiApiArg = {
  entityCountArgs: EntityCountArgs;
};
export type PostApiV1ReprovisynEntitiesByEntityIdCheckDataAvailabilityApiResponse = /** status 200 undefined */ DataTypeEntityDataAvailabilityResponse;
export type PostApiV1ReprovisynEntitiesByEntityIdCheckDataAvailabilityApiArg = {
  entityId: string;
  dataTypeEntityDataAvailability: DataTypeEntityDataAvailability;
};
export type GetApiV1ReprovisynEntitiesByEntityIdCountApiResponse = /** status 200 undefined */ number;
export type GetApiV1ReprovisynEntitiesByEntityIdCountApiArg = {
  entityId: string;
};
export type PostApiV1ReprovisynEntitiesByEntityIdCountApiResponse = /** status 200 undefined */ number;
export type PostApiV1ReprovisynEntitiesByEntityIdCountApiArg = {
  entityId: string;
  countArgs: CountArgs;
};
export type GetApiV1ReprovisynEntitiesByEntityIdDataApiResponse = /** status 200 undefined */ object[];
export type GetApiV1ReprovisynEntitiesByEntityIdDataApiArg = {
  entityId: string;
  dataArgs: DataArgs;
};
export type PostApiV1ReprovisynEntitiesByEntityIdDataApiResponse = /** status 200 undefined */ object[];
export type PostApiV1ReprovisynEntitiesByEntityIdDataApiArg = {
  entityId: string;
  dataArgs: DataArgs;
};
export type PostApiV1ReprovisynEntitiesByEntityIdIsCampaignableApiResponse = /** status 200 undefined */ IdType[];
export type PostApiV1ReprovisynEntitiesByEntityIdIsCampaignableApiArg = {
  entityId: string;
  campaignableArgs: CampaignableArgs;
};
export type GetApiV1ReprovisynEntitiesByEntityIdMetadataApiResponse = /** status 200 undefined */ EntityMeta;
export type GetApiV1ReprovisynEntitiesByEntityIdMetadataApiArg = {
  entityId: string;
};
export type PostApiV1ReprovisynEntitiesByEntityIdMetadataApiResponse = /** status 200 undefined */ EntityMeta;
export type PostApiV1ReprovisynEntitiesByEntityIdMetadataApiArg = {
  entityId: string;
  entityArgs: EntityArgs;
};
export type PostApiV1ReprovisynEntitiesByEntityIdSearchApiResponse = /** status 200 undefined */ object[];
export type PostApiV1ReprovisynEntitiesByEntityIdSearchApiArg = {
  entityId: string;
  searchArgs: SearchArgs;
};
export type PostApiV1ReprovisynEntityMappingScoreByFromIdAndToIdApiResponse = /** status 200 undefined */ ScoreRow[];
export type PostApiV1ReprovisynEntityMappingScoreByFromIdAndToIdApiArg = {
  fromId: string;
  toId: string;
  entityMappingDataArgs: EntityMappingDataArgs;
};
export type GetApiV1ReprovisynEntityMappingsByFromIdApiResponse = /** status 200 undefined */ string[];
export type GetApiV1ReprovisynEntityMappingsByFromIdApiArg = {
  fromId: string;
};
export type PostApiV1ReprovisynEntityMappingsMultiApiResponse = /** status 200 undefined */ {
  [key: string]: string[];
};
export type PostApiV1ReprovisynEntityMappingsMultiApiArg = {
  mappingIds: MappingIds;
};
export type GetApiV1ReprovisynFileByUuidMetadataApiResponse = /** status 200 undefined */ UploadedEntityMeta;
export type GetApiV1ReprovisynFileByUuidMetadataApiArg = {
  uuid: string;
};
export type GetApiV1ReprovisynIdtypeMappingsApiResponse = /** status 200 undefined */ string[];
export type GetApiV1ReprovisynIdtypeMappingsApiArg = void;
export type GetApiV1ReprovisynIdtypeMappingsByFromIdApiResponse = /** status 200 undefined */ string[];
export type GetApiV1ReprovisynIdtypeMappingsByFromIdApiArg = {
  fromId: string;
};
export type PostApiV1ReprovisynIdtypeMappingsByFromIdAndToIdApiResponse = /** status 200 undefined */ string[][];
export type PostApiV1ReprovisynIdtypeMappingsByFromIdAndToIdApiArg = {
  fromId: string;
  toId: string;
  mappingIds: MappingIds;
};
export type GetApiV1ReprovisynIdtypesApiResponse = /** status 200 undefined */ IdType[];
export type GetApiV1ReprovisynIdtypesApiArg = void;
export type GetApiV1ReprovisynNamedIdSetsApiResponse = /** status 200 undefined */ NamedIdSet[];
export type GetApiV1ReprovisynNamedIdSetsApiArg = void;
export type PostApiV1ReprovisynNamedIdSetsApiResponse = /** status 200 undefined */ NamedIdSet[];
export type PostApiV1ReprovisynNamedIdSetsApiArg = {
  namedIdSetFilterArgs: NamedIdSetFilterArgs;
};
export type PostApiV1ReprovisynNamedIdSetsCreateApiResponse = /** status 200 undefined */ NamedIdSet;
export type PostApiV1ReprovisynNamedIdSetsCreateApiArg = {
  namedIdSetCreateArgs: NamedIdSetCreateArgs;
};
export type DeleteApiV1ReprovisynNamedIdSetsBySetIdApiResponse = /** status 200 undefined */ NamedIdSet;
export type DeleteApiV1ReprovisynNamedIdSetsBySetIdApiArg = {
  setId: string;
};
export type PutApiV1ReprovisynNamedIdSetsBySetIdApiResponse = /** status 200 undefined */ NamedIdSet;
export type PutApiV1ReprovisynNamedIdSetsBySetIdApiArg = {
  setId: string;
  namedIdSetUpdateArgs: NamedIdSetUpdateArgs;
};
export type GetApiV1ReprovisynNamedIdSetsBySetIdDataApiResponse = /** status 200 undefined */ string[];
export type GetApiV1ReprovisynNamedIdSetsBySetIdDataApiArg = {
  setId: string;
};
export type GetApiV1ReprovisynNamedIdSetsBySetIdMetadataApiResponse = /** status 200 undefined */ NamedIdSet;
export type GetApiV1ReprovisynNamedIdSetsBySetIdMetadataApiArg = {
  setId: string;
};
export type GetApiV1ReprovisynProxyViewsApiResponse = /** status 200 undefined */ ProxyView[];
export type GetApiV1ReprovisynProxyViewsApiArg = void;
export type GetApiV1ReprovisynRelationsApiResponse = /** status 200 undefined */ (
  | RelationOneOne
  | RelationOneN
  | RelationMn
  | RelationOneNSelection
  | RelationMnSelection
  | RelationDrilldown
)[];
export type GetApiV1ReprovisynRelationsApiArg = void;
export type PostApiV1ReprovisynRelationsApiResponse = /** status 200 undefined */ (
  | RelationOneOne
  | RelationOneN
  | RelationMn
  | RelationOneNSelection
  | RelationMnSelection
  | RelationDrilldown
)[];
export type PostApiV1ReprovisynRelationsApiArg = {
  relationsArgs: RelationsArgs;
};
export type PostApiV1ReprovisynRelationsDataApiResponse = /** status 200 undefined */ object[];
export type PostApiV1ReprovisynRelationsDataApiArg = {
  mappingRelationArgs: MappingRelationArgs;
};
export type GetApiV1ReprovisynTestDatabaseConnectionsApiResponse = /** status 200 undefined */ MessageResponse;
export type GetApiV1ReprovisynTestDatabaseConnectionsApiArg = void;
export type Banner = {
  /** Background color of the banner. */
  backgroundColor?: string;
  /** Message to display in the banner. If empty, the banner will not be displayed. */
  message?: string;
};
export type Contact = {
  /** Email address to display in the contact information. This field is required. */
  email?: string;
  /** Name to display in the contact information. This field is optional. If empty, the email address will be displayed. */
  name?: string;
};
export type Customization = {
  /** Contact information to display in Ordino. */
  contact?: Contact;
  /** Welcome message to display on the Ordino welcome page. The message is displayed as a card and can contain more text than the banner notification. If empty, the welcome message will not be displayed. */
  welcomeMessage?: Banner;
};
export type AddColumnSidebarSectionsConfig = {
  /** Determines whether the section is expanded by default. */
  expanded?: boolean;
  /** Name of the section. */
  name: 'entity-columns' | 'data-columns' | 'special-columns';
};
export type AddColumnSidebarConfig = {
  /** List of sections in the add column sidebar. */
  sections?: AddColumnSidebarSectionsConfig[];
};
export type SidebarsConfig = {
  /** Configuration for customizing the behavior of the add column sidebar. */
  addColumnSidebar?: AddColumnSidebarConfig;
};
export type ViewChooserViewGroups = {
  /** Determines whether the view group is expanded by default. */
  expanded?: boolean;
  /** Name of the view group. */
  name?: string;
};
export type ViewChooserConfig = {
  /** Determines the behavior of the view chooser:
    
    - `expanded`: The view chooser is expanded by default.
    - `collapsed`: The view chooser is collapsed by default.
    - `auto`: The view chooser dynamically adjusts based on the number of open views.
    If only one view is open, it remains expanded; however, if multiple views are open, it collapses automatically. */
  sidebarMode?: 'expanded' | 'collapsed' | 'auto';
  /** List of view groups. Each view group contains a list of views. */
  viewGroups?: ViewChooserViewGroups[];
};
export type UiConfig = {
  /** Set the default entity id (e.g., `ordino_public.public.tdp_gene`) to skip the Ordino start menu with the data landscape and start with a workbench when opening Ordino. */
  defaultEntity?: string;
  /** Enable campaigns and insights management. If enabled, users can create, manage, and share campaigns and insights. */
  featureEnableInsightManagement?: boolean;
  /** Enable the SDF file upload. If enabled, users can upload SDF files to Ordino and link them to existing datasets of the landscape. */
  featureEnableSDFUpload?: boolean;
  /** Enable the analysis sessions. If enabled, users can create, manage, and share their analysis sessions. */
  featureEnableSessions?: boolean;
  /** Enable the file upload. If enabled, users can upload Excel and CSV files to Ordino and link them to existing datasets of the landscape. */
  featureEnableUpload?: boolean;
  /** Configuration to control the behavior of the landscape description. */
  landscapeDescription?: string;
  /** Configuration for customizing the UI behavior of the sidebars. */
  sidebars?: SidebarsConfig;
  /** Configuration to control the behavior of the terms and conditions. */
  termsAndConditions?: string;
  /** Configuration for customizing the UI behavior of the view chooser. */
  viewChooser?: ViewChooserConfig;
};
export type UploadFileConfig = {
  /** Maximum file size in bytes. If the uploaded file exceeds this size, the upload will be rejected. The default size is 20 MB. */
  maxFileSize?: number;
};
export type VersionResponse = {
  company?: string;
  daysLeft: number;
  expiryDate: string;
  featureStage: number;
  isTrial: boolean;
  totalDays: number;
};
export type AppQuestionLinkMeta = {
  app_id: string;
};
export type AppQuestionLink = {
  meta: AppQuestionLinkMeta;
  type?: 'app';
};
export type FileQuestionLinkMeta = {
  column: string;
  file: string;
};
export type FileQuestionLink = {
  meta: FileQuestionLinkMeta;
  type?: 'file';
};
export type HtmlQuestionLinkMeta = {
  html_link: string;
};
export type HtmlQuestionLink = {
  meta: HtmlQuestionLinkMeta;
  type?: 'html';
};
export type ScoreQuestionLinkMeta = {
  id: string;
  params?: object;
};
export type ScoreQuestionLink = {
  meta: ScoreQuestionLinkMeta;
  type?: 'score';
};
export type Question = {
  links?: (
    | ({
        type: 'app';
      } & AppQuestionLink)
    | ({
        type: 'file';
      } & FileQuestionLink)
    | ({
        type: 'html';
      } & HtmlQuestionLink)
    | ({
        type: 'score';
      } & ScoreQuestionLink)
  )[];
  text: string;
  type: string;
};
export type CampaignType = {
  /** Default color derived from the entity */
  color?: string;
  description: string;
  name: string;
  predefinedQuestions?: Question[];
  type: string;
};
export type MessageResponse = {
  message: string;
};
export type BooleanColumn = {
  /** The color for the column as HEX value. This might be overriden by the entity color or the renderer. */
  color?: string;
  /** The name of the column in the database. */
  columnName: string;
  /** Specifies the query categories applied by default */
  defaultQueryEnum?: (number | number | string | boolean | any[])[];
  /** Multi-line description of the column. */
  description?: string;
  /** Whether the column should be excluded from the frontend. This is useful for columns that are only used for internal purposes. */
  excluded?: boolean;
  /** String to show for `false` values. */
  falseMarker?: string;
  /** @experimental Whether the column can be removed or not. */
  fixed?: boolean;
  /** @experimental Whether the column is placed to the left side of the data table and remains fixed when scrolling horizontally. */
  frozen?: boolean;
  /** Default group renderer to use */
  groupRenderer?: 'default' | ('catdistributionbar' | 'categorical' | 'set' | 'table');
  /** The icon for the column. Can be a font-awesome icon name. */
  icon?: string;
  /** A global alias for this column, that you can use instead of db.table.schema.entity.column. */
  idtype?: string;
  /** Whether the column is visible in the initial ranking, i.e., when launching a new workbench with a data table view. */
  initialRanking?: boolean;
  /** Label of the column used in the user interface. If the label is not set, the column name is used. */
  label?: string;
  /** Whether to render the label as HTML (unsafe). */
  labelAsHTML?: boolean;
  /** True if the column is used for a mapping drilldown. */
  mapping?: boolean;
  /** Determines the order of columns in the score column dialog and details sidebar. */
  order?: number;
  /** `True` if the column is a primary key column for the entity. If multiple columns of the same entity are marked as primary, the primary key is a composite key (i.e., concatinated column names). */
  primary?: boolean;
  /** Default cell renderer to use */
  renderer?: 'boolean' | 'default' | ('categorical' | 'set' | 'table' | 'cattick');
  /** True if the column is used to search for items in the entity. */
  searchable?: boolean;
  /** Column summary line (subtitle). */
  summary?: string;
  /** Whether to render the summary as HTML (unsafe) or a custom function to convert a column to its summary label in HTML. */
  summaryAsHtml?: boolean;
  /** Default summary renderer in the header or filter sidebar. */
  summaryRenderer?: 'default' | ('catdistributionbar' | 'categorical' | 'set' | 'table');
  /** String to show for `true` values. */
  trueMarker?: string;
  /** List of values that indicate `true` values (case insensitive). */
  trueValues?: string[];
  type?: 'boolean' | 'BOOLEAN';
  /** Initial width of the column. */
  width?: number;
};
export type Category = {
  color?: string;
  icon?: string;
  label?: string;
  name: string;
  value?: number;
};
export type CategoricalColumn = {
  /** List of categories, either as strings or as Category objects */
  categories?: (Category | string)[];
  /** Possible values are:
    - `given` no sorting applied
    - `small-to-large` sort categories by number of entries per category from small to large
    - `large-to-small` sort categories by number of entries per category from large to small
    - `letters-numbers` sort letters first, then natural
    - `numbers-letters` sort natural numbers first, then letters
    - `chromosomes` sorts the given categories ascending by numbers and then x, y, mt (muation) */
  categoryOrder?: 'chromosomes' | 'given' | 'small-to-large' | 'large-to-small' | 'letters-numbers' | 'numbers-letters';
  /** The color for the column as HEX value. This might be overriden by the entity color or the renderer. */
  color?: string;
  /** The name of the column in the database. */
  columnName: string;
  /** Specifies the query categories applied by default */
  defaultQueryEnum?: (number | number | string | boolean | any[])[];
  /** Multi-line description of the column. */
  description?: string;
  /** Whether the column should be excluded from the frontend. This is useful for columns that are only used for internal purposes. */
  excluded?: boolean;
  /** @experimental Whether the column can be removed or not. */
  fixed?: boolean;
  /** @experimental Whether the column is placed to the left side of the data table and remains fixed when scrolling horizontally. */
  frozen?: boolean;
  /** Default group renderer to use */
  groupRenderer?: 'default' | ('catdistributionbar' | 'categorical' | 'set' | 'table');
  /** The icon for the column. Can be a font-awesome icon name. */
  icon?: string;
  /** A global alias for this column, that you can use instead of db.table.schema.entity.column. */
  idtype?: string;
  /** Whether the column is visible in the initial ranking, i.e., when launching a new workbench with a data table view. */
  initialRanking?: boolean;
  /** Label of the column used in the user interface. If the label is not set, the column name is used. */
  label?: string;
  /** Whether to render the label as HTML (unsafe). */
  labelAsHTML?: boolean;
  /** True if the column is used for a mapping drilldown. */
  mapping?: boolean;
  /** Determines the order of columns in the score column dialog and details sidebar. */
  order?: number;
  /** `True` if the column is a primary key column for the entity. If multiple columns of the same entity are marked as primary, the primary key is a composite key (i.e., concatinated column names). */
  primary?: boolean;
  /** Default cell renderer to use */
  renderer?: 'default' | ('categorical' | 'set' | 'table' | 'cattick');
  /** True if the column is used to search for items in the entity. */
  searchable?: boolean;
  /** Column summary line (subtitle). */
  summary?: string;
  /** Whether to render the summary as HTML (unsafe) or a custom function to convert a column to its summary label in HTML. */
  summaryAsHtml?: boolean;
  /** Default summary renderer in the header or filter sidebar. */
  summaryRenderer?: 'default' | ('catdistributionbar' | 'categorical' | 'set' | 'table');
  type?: 'categorical';
  /** Initial width of the column. */
  width?: number;
};
export type LinkColumn = {
  /** The color for the column as HEX value. This might be overriden by the entity color or the renderer. */
  color?: string;
  /** The name of the column in the database. */
  columnName: string;
  /** Specifies the query categories applied by default */
  defaultQueryEnum?: (number | number | string | boolean | any[])[];
  /** Multi-line description of the column. */
  description?: string;
  /** Whether the column should be excluded from the frontend. This is useful for columns that are only used for internal purposes. */
  excluded?: boolean;
  /** @experimental Whether the column can be removed or not. */
  fixed?: boolean;
  /** @experimental Whether the column is placed to the left side of the data table and remains fixed when scrolling horizontally. */
  frozen?: boolean;
  /** Default group renderer to use */
  groupRenderer?: 'image' | 'link';
  /** The icon for the column. Can be a font-awesome icon name. */
  icon?: string;
  /** A global alias for this column, that you can use instead of db.table.schema.entity.column. */
  idtype?: string;
  /** Whether the column is visible in the initial ranking, i.e., when launching a new workbench with a data table view. */
  initialRanking?: boolean;
  /** Label of the column used in the user interface. If the label is not set, the column name is used. */
  label?: string;
  /** Whether to render the label as HTML (unsafe). */
  labelAsHTML?: boolean;
  /** True if the column is used for a mapping drilldown. */
  mapping?: boolean;
  /** Determines the order of columns in the score column dialog and details sidebar. */
  order?: number;
  /** Replacement pattern: use `${value}` for the current value, `${escapedValue}` for an url safe value and `${item}` for the whole item. */
  pattern?: string;
  /** An optional list of pattern templates. */
  patternTemplates?: string[];
  /** `True` if the column is a primary key column for the entity. If multiple columns of the same entity are marked as primary, the primary key is a composite key (i.e., concatinated column names). */
  primary?: boolean;
  /** Default cell renderer to use */
  renderer?: ('image' | 'link') | 'string';
  /** True if the column is used to search for items in the entity. */
  searchable?: boolean;
  /** Column summary line (subtitle). */
  summary?: string;
  /** Whether to render the summary as HTML (unsafe) or a custom function to convert a column to its summary label in HTML. */
  summaryAsHtml?: boolean;
  /** Default summary renderer in the header or filter sidebar. */
  summaryRenderer?: 'default';
  type?: 'link';
  /** Initial width of the column. */
  width?: number;
};
export type DataMap = {
  /** Input range of the mapping. */
  domain: (number | null)[];
  /** Output range of the mapping. */
  range: number[];
  /** Type of the data mapping. */
  type: 'linear' | 'log';
};
export type NumericalColumn = {
  /** The color for the column as HEX value. This might be overriden by the entity color or the renderer. */
  color?: string;
  /** The name of the column in the database. */
  columnName: string;
  /** Specifies the query categories applied by default */
  defaultQueryEnum?: (number | number | string | boolean | any[])[];
  /** Multi-line description of the column. */
  description?: string;
  /** Whether the column should be excluded from the frontend. This is useful for columns that are only used for internal purposes. */
  excluded?: boolean;
  /** The accuracy defines the deviation of values to the applied filter boundary. Use an accuracy closer to 0 for columns with smaller numbers (e.g., 1e-9). */
  filterAccuracy?: number;
  /** @experimental Whether the column can be removed or not. */
  fixed?: boolean;
  /** @experimental Whether the column is placed to the left side of the data table and remains fixed when scrolling horizontally. */
  frozen?: boolean;
  /** Default group renderer to use */
  groupRenderer?: 'default' | ('boxplot' | 'histogram' | 'violin') | 'dot';
  /** The icon for the column. Can be a font-awesome icon name. */
  icon?: string;
  /** A global alias for this column, that you can use instead of db.table.schema.entity.column. */
  idtype?: string;
  /** Whether the column is visible in the initial ranking, i.e., when launching a new workbench with a data table view. */
  initialRanking?: boolean;
  /** Label of the column used in the user interface. If the label is not set, the column name is used. */
  label?: string;
  /** Whether to render the label as HTML (unsafe). */
  labelAsHTML?: boolean;
  /** Define the data mapping of the column to map the values to a linear or logarithmic scale and visualize them accordingly. */
  map?: DataMap;
  /** True if the column is used for a mapping drilldown. */
  mapping?: boolean;
  /** Maximum value of the column. */
  max?: number;
  /** Minimum value of the column. */
  min?: number;
  /** Number format according to https://d3js.org/d3-format. */
  numberFormat?: string;
  /** Determines the order of columns in the score column dialog and details sidebar. */
  order?: number;
  /** `True` if the column is a primary key column for the entity. If multiple columns of the same entity are marked as primary, the primary key is a composite key (i.e., concatinated column names). */
  primary?: boolean;
  /** Default cell renderer to use */
  renderer?: 'default' | ('number' | 'brightness' | 'dot' | 'circle' | 'tick');
  /** True if the column is used to search for items in the entity. */
  searchable?: boolean;
  /** Whether to render a representation of the minimum value as some visual glyph, i.e. a bar with 1 px instead of 0 px. */
  showMinimumRepresentation?: boolean;
  /** Column summary line (subtitle). */
  summary?: string;
  /** Whether to render the summary as HTML (unsafe) or a custom function to convert a column to its summary label in HTML. */
  summaryAsHtml?: boolean;
  /** Default summary renderer in the header or filter sidebar. */
  summaryRenderer?: 'default' | ('boxplot' | 'histogram' | 'violin');
  type?: 'number';
  /** Initial width of the column. */
  width?: number;
};
export type SmilesColumn = {
  /** The color for the column as HEX value. This might be overriden by the entity color or the renderer. */
  color?: string;
  /** The name of the column in the database. */
  columnName: string;
  /** Specifies the query categories applied by default */
  defaultQueryEnum?: (number | number | string | boolean | any[])[];
  /** Multi-line description of the column. */
  description?: string;
  /** Whether the column should be excluded from the frontend. This is useful for columns that are only used for internal purposes. */
  excluded?: boolean;
  /** @experimental Whether the column can be removed or not. */
  fixed?: boolean;
  /** @experimental Whether the column is placed to the left side of the data table and remains fixed when scrolling horizontally. */
  frozen?: boolean;
  /** Default group renderer */
  groupRenderer?:
    | 'boolean'
    | 'boxplot'
    | 'brightness'
    | 'catdistributionbar'
    | 'categorical'
    | 'circle'
    | 'date'
    | 'default'
    | 'dot'
    | 'group'
    | 'heatmap'
    | 'catheatmap'
    | 'histogram'
    | 'datehistogram'
    | 'image'
    | 'interleaving'
    | 'link'
    | 'linkMap'
    | 'loading'
    | 'nested'
    | 'number'
    | 'mapbars'
    | 'rank'
    | 'selection'
    | 'set'
    | 'sparkline'
    | 'stack'
    | 'string'
    | 'stringhist'
    | 'table'
    | 'upset'
    | 'verticalbar'
    | 'violin'
    | 'tick'
    | 'cattick';
  /** The icon for the column. Can be a font-awesome icon name. */
  icon?: string;
  /** A global alias for this column, that you can use instead of db.table.schema.entity.column. */
  idtype?: string;
  /** Whether the column is visible in the initial ranking, i.e., when launching a new workbench with a data table view. */
  initialRanking?: boolean;
  /** Label of the column used in the user interface. If the label is not set, the column name is used. */
  label?: string;
  /** Whether to render the label as HTML (unsafe). */
  labelAsHTML?: boolean;
  /** True if the column is used for a mapping drilldown. */
  mapping?: boolean;
  /** Determines the order of columns in the score column dialog and details sidebar. */
  order?: number;
  /** `True` if the column is a primary key column for the entity. If multiple columns of the same entity are marked as primary, the primary key is a composite key (i.e., concatinated column names). */
  primary?: boolean;
  /** Default cell renderer */
  renderer?:
    | 'boolean'
    | 'boxplot'
    | 'brightness'
    | 'catdistributionbar'
    | 'categorical'
    | 'circle'
    | 'date'
    | 'default'
    | 'dot'
    | 'group'
    | 'heatmap'
    | 'catheatmap'
    | 'histogram'
    | 'datehistogram'
    | 'image'
    | 'interleaving'
    | 'link'
    | 'linkMap'
    | 'loading'
    | 'nested'
    | 'number'
    | 'mapbars'
    | 'rank'
    | 'selection'
    | 'set'
    | 'sparkline'
    | 'stack'
    | 'string'
    | 'stringhist'
    | 'table'
    | 'upset'
    | 'verticalbar'
    | 'violin'
    | 'tick'
    | 'cattick';
  /** True if the column is used to search for items in the entity. */
  searchable?: boolean;
  /** Column summary line (subtitle). */
  summary?: string;
  /** Whether to render the summary as HTML (unsafe) or a custom function to convert a column to its summary label in HTML. */
  summaryAsHtml?: boolean;
  /** Default summary renderer in the header or filter sidebar. */
  summaryRenderer?:
    | 'boolean'
    | 'boxplot'
    | 'brightness'
    | 'catdistributionbar'
    | 'categorical'
    | 'circle'
    | 'date'
    | 'default'
    | 'dot'
    | 'group'
    | 'heatmap'
    | 'catheatmap'
    | 'histogram'
    | 'datehistogram'
    | 'image'
    | 'interleaving'
    | 'link'
    | 'linkMap'
    | 'loading'
    | 'nested'
    | 'number'
    | 'mapbars'
    | 'rank'
    | 'selection'
    | 'set'
    | 'sparkline'
    | 'stack'
    | 'string'
    | 'stringhist'
    | 'table'
    | 'upset'
    | 'verticalbar'
    | 'violin'
    | 'tick'
    | 'cattick';
  type?: 'smiles';
  /** Initial width of the column. */
  width?: number;
};
export type StringColumn = {
  /** The color for the column as HEX value. This might be overriden by the entity color or the renderer. */
  color?: string;
  /** The name of the column in the database. */
  columnName: string;
  /** Specifies the query categories applied by default */
  defaultQueryEnum?: (number | number | string | boolean | any[])[];
  /** Multi-line description of the column. */
  description?: string;
  /** Whether the column should be excluded from the frontend. This is useful for columns that are only used for internal purposes. */
  excluded?: boolean;
  /** @experimental Whether the column can be removed or not. */
  fixed?: boolean;
  /** @experimental Whether the column is placed to the left side of the data table and remains fixed when scrolling horizontally. */
  frozen?: boolean;
  /** Default group renderer to use */
  groupRenderer?: 'default' | ('string' | 'stringhist');
  /** The icon for the column. Can be a font-awesome icon name. */
  icon?: string;
  /** A global alias for this column, that you can use instead of db.table.schema.entity.column. */
  idtype?: string;
  /** Whether the column is visible in the initial ranking, i.e., when launching a new workbench with a data table view. */
  initialRanking?: boolean;
  /** Label of the column used in the user interface. If the label is not set, the column name is used. */
  label?: string;
  /** Whether to render the label as HTML (unsafe). */
  labelAsHTML?: boolean;
  /** True if the column is used for a mapping drilldown. */
  mapping?: boolean;
  /** Determines the order of columns in the score column dialog and details sidebar. */
  order?: number;
  /** `True` if the column is a primary key column for the entity. If multiple columns of the same entity are marked as primary, the primary key is a composite key (i.e., concatinated column names). */
  primary?: boolean;
  /** Default cell renderer to use */
  renderer?: 'default' | ('string' | 'stringhist');
  /** True if the column is used to search for items in the entity. */
  searchable?: boolean;
  /** Column summary line (subtitle). */
  summary?: string;
  /** Whether to render the summary as HTML (unsafe) or a custom function to convert a column to its summary label in HTML. */
  summaryAsHtml?: boolean;
  /** Default summary renderer in the header or filter sidebar. */
  summaryRenderer?: 'default' | ('string' | 'stringhist');
  type?: 'string' | 'STRING';
  /** Initial width of the column. */
  width?: number;
};
export type NumberArrayColumn = {
  /** The color for the column as HEX value. This might be overriden by the entity color or the renderer. */
  color?: string;
  /** The name of the column in the database. */
  columnName: string;
  /** Specifies the query categories applied by default */
  defaultQueryEnum?: (number | number | string | boolean | any[])[];
  /** Delimiter for the array column */
  delimiter?: ',' | ';' | '|';
  /** Multi-line description of the column. */
  description?: string;
  /** Whether the column should be excluded from the frontend. This is useful for columns that are only used for internal purposes. */
  excluded?: boolean;
  /** @experimental Whether the column can be removed or not. */
  fixed?: boolean;
  /** @experimental Whether the column is placed to the left side of the data table and remains fixed when scrolling horizontally. */
  frozen?: boolean;
  /** Default group renderer to use */
  groupRenderer?: 'default' | ('boxplot' | 'dot' | 'heatmap' | 'histogram' | 'default' | 'sparkline' | 'violin');
  /** The icon for the column. Can be a font-awesome icon name. */
  icon?: string;
  /** A global alias for this column, that you can use instead of db.table.schema.entity.column. */
  idtype?: string;
  /** Whether the column is visible in the initial ranking, i.e., when launching a new workbench with a data table view. */
  initialRanking?: boolean;
  /** Label of the column used in the user interface. If the label is not set, the column name is used. */
  label?: string;
  /** Whether to render the label as HTML (unsafe). */
  labelAsHTML?: boolean;
  /** True if the column is used for a mapping drilldown. */
  mapping?: boolean;
  /** Determines the order of columns in the score column dialog and details sidebar. */
  order?: number;
  /** `True` if the column is a primary key column for the entity. If multiple columns of the same entity are marked as primary, the primary key is a composite key (i.e., concatinated column names). */
  primary?: boolean;
  /** Default cell renderer to use */
  renderer?: 'default' | ('verticalbar' | 'boxplot' | 'dot' | 'heatmap' | 'histogram' | 'sparkline' | 'tick');
  /** True if the column is used to search for items in the entity. */
  searchable?: boolean;
  /** Column summary line (subtitle). */
  summary?: string;
  /** Whether to render the summary as HTML (unsafe) or a custom function to convert a column to its summary label in HTML. */
  summaryAsHtml?: boolean;
  /** Default summary renderer in the header or filter sidebar. */
  summaryRenderer?: 'default' | ('boxplot' | 'histogram' | 'violin');
  type?: 'numberArray';
  /** Initial width of the column. */
  width?: number;
};
export type StringArrayColumn = {
  /** The color for the column as HEX value. This might be overriden by the entity color or the renderer. */
  color?: string;
  /** The name of the column in the database. */
  columnName: string;
  /** Specifies the query categories applied by default */
  defaultQueryEnum?: (number | number | string | boolean | any[])[];
  /** Delimiter for the array column */
  delimiter?: ',' | ';' | '|';
  /** Multi-line description of the column. */
  description?: string;
  /** Whether the column should be excluded from the frontend. This is useful for columns that are only used for internal purposes. */
  excluded?: boolean;
  /** @experimental Whether the column can be removed or not. */
  fixed?: boolean;
  /** @experimental Whether the column is placed to the left side of the data table and remains fixed when scrolling horizontally. */
  frozen?: boolean;
  /** Default group renderer to use */
  groupRenderer?: 'default' | ('string' | 'stringhist');
  /** The icon for the column. Can be a font-awesome icon name. */
  icon?: string;
  /** A global alias for this column, that you can use instead of db.table.schema.entity.column. */
  idtype?: string;
  /** Whether the column is visible in the initial ranking, i.e., when launching a new workbench with a data table view. */
  initialRanking?: boolean;
  /** Label of the column used in the user interface. If the label is not set, the column name is used. */
  label?: string;
  /** Whether to render the label as HTML (unsafe). */
  labelAsHTML?: boolean;
  /** True if the column is used for a mapping drilldown. */
  mapping?: boolean;
  /** Determines the order of columns in the score column dialog and details sidebar. */
  order?: number;
  /** `True` if the column is a primary key column for the entity. If multiple columns of the same entity are marked as primary, the primary key is a composite key (i.e., concatinated column names). */
  primary?: boolean;
  /** Default cell renderer to use */
  renderer?: 'default' | ('string' | 'stringhist');
  /** True if the column is used to search for items in the entity. */
  searchable?: boolean;
  /** Column summary line (subtitle). */
  summary?: string;
  /** Whether to render the summary as HTML (unsafe) or a custom function to convert a column to its summary label in HTML. */
  summaryAsHtml?: boolean;
  /** Default summary renderer in the header or filter sidebar. */
  summaryRenderer?:
    | 'boolean'
    | 'boxplot'
    | 'brightness'
    | 'catdistributionbar'
    | 'categorical'
    | 'circle'
    | 'date'
    | 'default'
    | 'dot'
    | 'group'
    | 'heatmap'
    | 'catheatmap'
    | 'histogram'
    | 'datehistogram'
    | 'image'
    | 'interleaving'
    | 'link'
    | 'linkMap'
    | 'loading'
    | 'nested'
    | 'number'
    | 'mapbars'
    | 'rank'
    | 'selection'
    | 'set'
    | 'sparkline'
    | 'stack'
    | 'string'
    | 'stringhist'
    | 'table'
    | 'upset'
    | 'verticalbar'
    | 'violin'
    | 'tick'
    | 'cattick';
  type?: 'stringArray';
  /** Initial width of the column. */
  width?: number;
};
export type Formatting = {
  defaultTokenSeparator?: string;
  idColumn: string;
  template?: string;
  titleColumn?: string;
  tokenSeparatorsRegex?: string;
};
export type GlobalQuery = {
  appliedCategories?: Category[];
  availableCategories?: Category[];
  defaultCategories?: Category[];
  name: string;
};
export type ReprovisynUser = {
  active: boolean;
  changedOn?: string;
  createdOn?: string;
  email: string;
  firstName?: string;
  id: number;
  lastLogin?: string;
  lastName?: string;
  username: string;
};
export type NamedIdSet = {
  changedBy?: ReprovisynUser;
  changedOn?: string;
  collabs: ReprovisynUser[];
  count?: number;
  createdBy?: ReprovisynUser;
  createdOn?: string;
  description?: string;
  entityId: string;
  globalQueryAppliedCategories?: Category[];
  globalQueryName?: string;
  id?: number;
  name: string;
  public: boolean;
  source: 'config' | 'data_table' | 'user';
};
export type FilterItem = {
  col: string;
  op: string;
  val: number | number | string | boolean | any[];
};
export type ProxyViewConfig = {
  argument: string;
  description?: string;
  fallbackSite?: string;
  icon?: string;
  mappingFallbackEnd?: string;
  name: string;
  order?: number;
  selection: '0' | '1' | 'some' | 'none' | 'any' | 'single' | 'small_multiple' | 'multiple' | 'chooser' | '2';
  site: string;
  topics?: string[];
  visynViewType?: string;
};
export type ViewGroup = {
  filter?: FilterItem;
  name: string;
  order?: number;
  views?: ProxyViewConfig[];
};
export type ProxyView = {
  argument: string;
  description?: string;
  entityName?: string;
  fallbackSite?: string;
  filter?: {
    [key: string]: string;
  };
  group?: ViewGroup;
  icon?: string;
  idtype: string;
  mappingFallbackEnd?: string;
  mappingPrimaryEnd: string;
  mappingStart: string;
  name: string;
  order?: number;
  selection: '0' | '1' | 'some' | 'none' | 'any' | 'single' | 'small_multiple' | 'multiple' | 'chooser' | '2';
  site: string;
  topics?: string[];
  visynViewType?: string;
};
export type Visibility = {
  /** Hide this entity in the _Add columns sidebar_ (specifically in _Data columns_ section) when set to `true`. If unset or set to `false`, the entity is visible in the _Add columns sidebar_. */
  hiddenInColumnSidebar?: boolean;
  /** Hide this entity in the _Data landscape_ when set to `true`. If unset or set to `false`, the entity is visible in the _Data landscape_. */
  hiddenInLandscape?: boolean;
  /** Hide this entity in the _View chooser_ when set to `true`. If unset or set to `false`, the entity is visible in the _View chooser_. */
  hiddenInViewChooser?: boolean;
};
export type ColumnSorting = {
  /** Whether to sort in ascending order. */
  asc?: boolean;
  /** The column to sort by. */
  sortBy?: string;
};
export type MatchColumn = {
  /** The column name. */
  column?: string;
  /** The description of the condition. Will be shown as a disabled tooltip in the chooser. */
  description?: string;
  /** The type of the condition. */
  type?: 'value';
  /** The value to compare. */
  value?: (string | number)[];
};
export type RankingConfig = {
  /** Data provider dump: Used to restore a preconfigured representation of the ranking */
  dataProviderDump?: object;
  /** Whether to show the aggregate column. */
  showAggregate?: boolean;
  /** Whether to show the rank column. */
  showRank?: boolean;
  /** Whether to show the selection column. */
  showSelection?: boolean;
};
export type Ranking = {
  /** The ranking view configuration. */
  config?: RankingConfig;
  /** The description of the view. This is used to display additional information about the view in the tooltip. */
  description?: string;
  /** The icon of the view. Use the font-awesome icon class. For example, 'fas fa-chart-bar'. */
  icon?: string;
  /** The name of the view. This is used to display the view in the workbench. */
  name?: string;
  /** Whether the view is open in the workbench by default. */
  open?: boolean;
  /** The type of the view. */
  type?: 'ranking';
};
export type VisynTableViewConfig = {
  /** Whether to show the selection column. */
  showSelection?: boolean;
};
export type VisynTableView = {
  /** The visyn table view configuration. */
  config?: VisynTableViewConfig;
  /** The description of the view. This is used to display additional information about the view in the tooltip. */
  description?: string;
  /** The icon of the view. Use the font-awesome icon class. For example, 'fas fa-chart-bar'. */
  icon?: string;
  /** The name of the view. This is used to display the view in the workbench. */
  name?: string;
  /** Whether the view is open in the workbench by default. */
  open?: boolean;
  /** The type of the view. */
  type?: 'visyn-table';
};
export type GenericView = {
  /** The description of the view. This is used to display additional information about the view in the tooltip. */
  description?: string;
  /** The icon of the view. Use the font-awesome icon class. For example, 'fas fa-chart-bar'. */
  icon?: string;
  /** The name of the view. This is used to display the view in the workbench. */
  name?: string;
  /** Whether the view is open in the workbench by default. */
  open?: boolean;
  /** The type of the view. This is used to determine which component to render. */
  type?: 'view';
};
export type VisColumnInfo = {
  /** The description of the column. */
  description?: string;
  /** The id of the column. */
  id?: string;
  /** The name of the column. */
  name?: string;
};
export type VisBarSortState = {
  /** The sort state for the x-axis. */
  x?: 'Ascending' | 'Descending' | 'None';
  /** The sort state for the y-axis. */
  y?: 'Ascending' | 'Descending' | 'None';
};
export type VisBarConfig = {
  /** The column to use for the aggregation. */
  aggregateColumn?: VisColumnInfo;
  /** The type of aggregation to use. */
  aggregateType?: 'Count' | 'Minimum' | 'Average' | 'Median' | 'Maximum';
  /** The categorical column to use for the bar chart. */
  catColumnSelected?: VisColumnInfo;
  /** The direction of the bar chart. */
  direction?: 'Vertical' | 'Horizontal';
  /** The display of the bar chart. */
  display?: 'Absolute' | 'Normalized';
  /** Select a column that is used to created small multiple bar charts. */
  facets?: VisColumnInfo;
  /** The column to use for grouping. */
  group?: VisColumnInfo;
  /** The type of grouping to use. */
  groupType?: 'Grouped' | 'Stacked';
  /** The numerical columns to use for the bar chart. */
  numColumnsSelected?: VisColumnInfo[];
  /** The sort state of the bar chart. */
  sortState?: VisBarSortState;
  /** The type of the visualization. */
  type?: 'Bar chart';
};
export type VisCorrelationConfig = {
  /** The type of correlation to calculate. */
  correlationType?: 'Pearson' | 'Spearman';
  /** The numerical columns to calculate the correlation for. */
  numColumnsSelected?: VisColumnInfo[];
  /** The domain for the p-value. */
  pDomain?: number[];
  /** The scale type for the p-value. */
  pScaleType?: 'Linear' | 'Log';
  /** The type of the visualization. */
  type?: 'Correlation';
};
export type VisHeatmapConfig = {
  /** The column to use for the aggregation. */
  aggregateColumn?: VisColumnInfo;
  /** The type of aggregation to use. */
  aggregateType?: 'Count' | 'Minimum' | 'Average' | 'Median' | 'Maximum';
  /** The categorical columns to use for the heatmap. */
  catColumnsSelected?: VisColumnInfo[];
  /** The column to use for the color. */
  color?: VisColumnInfo;
  /** Whether to enable animation. */
  isAnimationEnabled?: boolean;
  /** The color scale type for the numerical columns. */
  numColorScaleType?: 'Sequential' | 'Divergent';
  /** The type of the visualization. */
  type?: 'Heatmap plot';
  /** The sorting for the x-axis.
    - CAT_ASC: Categorical ascending
    - CAT_DESC: Categorical descending
    - VAL_ASC: Value ascending
    - VAL_DESC: Value descending */
  xSortedBy?: 'CAT_ASC' | 'CAT_DESC' | 'VAL_ASC' | 'VAL_DESC';
  /** The sorting for the x-axis.
    - CAT_ASC: Categorical ascending
    - CAT_DESC: Categorical descending
    - VAL_ASC: Value ascending
    - VAL_DESC: Value descending */
  ySortedBy?: 'CAT_ASC' | 'CAT_DESC' | 'VAL_ASC' | 'VAL_DESC';
};
export type VisHexbinConfig = {
  /** The column to use for the color. */
  color?: VisColumnInfo;
  /** The drag mode to use. */
  dragMode?: 'select' | 'lasso' | 'zoom' | 'pan';
  /** The hex radius. */
  hexRadius?: number;
  /** The hexbin options to use. */
  hexbinOptions?: 'Color' | 'Pie' | 'Bins';
  /** Whether to scale the opacity. */
  isOpacityScale?: boolean;
  /** Whether to scale the size. */
  isSizeScale?: boolean;
  /** The numerical columns to use for the hexbin plot. */
  numColumnsSelected?: VisColumnInfo[];
  /** The type of the visualization. */
  type?: 'Hexbin plot';
};
export type RegressionFitOptions = {
  /** The order of the polynomial regression line. */
  order?: number;
  /** The precision of the polynomial regression line. */
  precision?: number;
};
export type RegressionLineStyle = {
  /** The default selected color index for the regression line (from colors). */
  colorSelected?: number;
  /** Available color options for the regression line. */
  colors?: string[];
  /** The dash for the regression line. */
  dash?: 'solid' | 'dot' | 'dash' | 'longdash' | 'dashdot' | 'longdashdot';
  /** The width for the regression line. */
  width?: number;
};
export type RegressionLineOptions = {
  /** The fit options for the regression line. */
  fitOptions?: RegressionFitOptions;
  /** The line style for the regression line. */
  lineStyle?: RegressionLineStyle;
  /** Whether to show the statistics for the regression line. */
  showStats?: boolean;
  /** The type of the regression line. */
  type?: 'None' | 'Linear' | 'Polynomial';
};
export type VisScatterConfig = {
  /** Set the opacity of the items. */
  alphaSliderVal?: number;
  /** The column to use for the color. */
  color?: VisColumnInfo;
  /** The drag mode to use. */
  dragMode?: 'select' | 'lasso' | 'zoom' | 'pan';
  /** The column to use for faceting. */
  facets?: VisColumnInfo;
  /** The column to use for the item id. */
  idColumn?: VisColumnInfo;
  /** The columns to use for displaying additional information in the scatter plot tooltip. */
  labelColumns?: VisColumnInfo[];
  /** The color scale type for the numerical columns. */
  numColorScaleType?: 'Sequential' | 'Divergent';
  /** The numerical columns to use for the scatter plot. */
  numColumnsSelected?: VisColumnInfo[];
  /** Regression line options. */
  regressionLineOptions?: RegressionLineOptions;
  /** The column to use for the shape. */
  shape?: VisColumnInfo;
  /** The maximum number of labels to show for selected items. Set to '0' to show all labels. */
  showLabelLimit?: number;
  /** Show labels by default never, always, or for selected items only. Useful for taking screenshots of the scatter plot. Might cause occlusion of labels for dense datasets. */
  showLabels?: 'Never' | 'Always' | 'Selected';
  /** Configure to show or hide the legend by default. If a value is set, the Legend toggle will not be visible. */
  showLegend?: boolean;
  /** The type of the visualization. */
  type?: 'Scatter plot';
};
export type VisSankeyConfig = {
  /** The categorical columns to use for the sankey plot. */
  catColumnsSelected?: VisColumnInfo[];
  /** The type of the visualization. */
  type?: 'Sankey';
};
export type VisViolinConfig = {
  /** The categorical column to use for creating multiple violin plots. */
  catColumnSelected?: VisColumnInfo;
  /** The categorical column to use for faceting the violin plot. */
  facetBy?: VisColumnInfo;
  /** The numerical columns to use for the violin plot. */
  numColumnsSelected?: VisColumnInfo[];
  /** Set the overlay of the violin plot, e.g., box plot, strip. */
  overlay?: 'None' | 'Box' | 'Strip';
  /** The categorical column to use for creating violin sub-plots. */
  subCategorySelected?: VisColumnInfo;
  /** Set the y-axis to be synced or unsynced across facets. */
  syncYAxis?: 'unsynced' | 'synced' | 'merged';
  /** The type of the visualization. */
  type?: 'Violin plot';
};
export type Visualization = {
  /** The description of the view. This is used to display additional information about the view in the tooltip. */
  description?: string;
  /** The icon of the view. */
  icon?: string;
  /** The name of the view. */
  name?: string;
  /** Whether the view is open in the workbench by default. */
  open?: boolean;
  /** The type of the view. */
  type?: 'visualization';
  /** The visualization configuration. */
  visConfig?: VisBarConfig | VisCorrelationConfig | VisHeatmapConfig | VisHexbinConfig | VisScatterConfig | VisSankeyConfig | VisViolinConfig;
};
export type ScatterAxisConfig = {
  /** The default column to use for the axis. */
  defaultColumn?: string;
  /** Selected entity id for the default column. */
  entityId?: string;
};
export type CoExpression = {
  /** The description of the view. This is used to display additional information about the view in the tooltip. */
  description?: string;
  /** The icon of the view. Use the font-awesome icon class. For example, 'fas fa-chart-bar'. */
  icon?: string;
  /** The name of the view. This is used to display the view in the workbench. */
  name?: string;
  /** Whether the view is open in the workbench by default. */
  open?: boolean;
  /** The type of the view. */
  type?: 'co-expression';
  /** The configuration of the x-axis. */
  xAxis?: ScatterAxisConfig;
  /** The configuration of the y-axis. */
  yAxis?: ScatterAxisConfig;
};
export type ExpressionVsCopynumber = {
  /** The description of the view. This is used to display additional information about the view in the tooltip. */
  description?: string;
  /** The icon of the view. Use the font-awesome icon class. For example, 'fas fa-chart-bar'. */
  icon?: string;
  /** The name of the view. This is used to display the view in the workbench. */
  name?: string;
  /** Whether the view is open in the workbench by default. */
  open?: boolean;
  /** The type of the view. */
  type?: 'expression-vs-copynumber';
  /** The configuration of the x-axis. */
  xAxis?: ScatterAxisConfig;
  /** The configuration of the y-axis. */
  yAxis?: ScatterAxisConfig;
};
export type ClinicalData = {
  /** List of column names for the clinical data. */
  columns?: string[];
  /** Selected entity id where the selected columns can be found. */
  entityId?: string;
};
export type OncoprintMapping = {
  /** Mapping of category values to colors. The keys are the values and the values are the colors as hex strings. */
  colorMapping?: {
    [key: string]: string;
  };
  /** The default column to use for the axis. */
  defaultColumn?: string;
  /** Selected entity id for the default column. */
  entityId?: string;
  /** The mapping of the column name to the color. */
  mapping?: {
    [key: string]: string;
  };
};
export type OncoprintMappingWithDisabledMutations = {
  /** Mapping of category values to colors. The keys are the values and the values are the colors as hex strings. */
  colorMapping?: {
    [key: string]: string;
  };
  /** The default column to use for the axis. */
  defaultColumn?: string;
  /** List of disabled mutations. The keys are the mutation ids. */
  disabledMutations?: string[];
  /** Selected entity id for the default column. */
  entityId?: string;
  /** The mapping of the column name to the color. */
  mapping?: {
    [key: string]: string;
  };
};
export type Oncoprint = {
  /** The configuration of the clinical data. */
  clinicalData?: ClinicalData;
  /** The configuration of the copy number mapping. */
  copynumber?: OncoprintMapping;
  /** The description of the view. This is used to display additional information about the view in the tooltip. */
  description?: string;
  /** The configuration of the fusion mapping. */
  fusion?: OncoprintMapping;
  /** The icon of the view. Use the font-awesome icon class. For example, 'fas fa-chart-bar'. */
  icon?: string;
  /** The configuration of the mutation mapping. This field may also contain the disabled mutations. */
  mutation?: OncoprintMappingWithDisabledMutations;
  /** The name of the view. This is used to display the view in the workbench. */
  name?: string;
  /** Whether the view is open in the workbench by default. */
  open?: boolean;
  /** The type of the view. */
  type?: 'oncoprint';
};
export type SolventHeatmapSpecConfig = {
  /** The label of the specification. */
  label?: string;
  /** The value of the specification. */
  value?: number | number | string;
};
export type AzeotropeHeatmap = {
  /** The default specification to use for the solvent heatmap. */
  defaultSpec?: string;
  /** The description of the view. This is used to display additional information about the view in the tooltip. */
  description?: string;
  /** The entity id for the solvent heatmap. */
  entityId?: string;
  /** The icon of the view. Use the font-awesome icon class. For example, 'fas fa-chart-bar'. */
  icon?: string;
  /** The name of the view. This is used to display the view in the workbench. */
  name?: string;
  /** Whether the view is open in the workbench by default. */
  open?: boolean;
  /** List of specification configurations. */
  specs?: SolventHeatmapSpecConfig[];
  /** The type of the view. */
  type?: 'azeotrope-heatmap';
};
export type SolventHeatmapColumnConfig = {
  /** List of colors for the color scale. The number of items must match with the 'valueRange'.
    Example: ['#198754', '#ffc107', '#dc3545', '#14225d']. */
  colorScale?: string[];
  /** The column name available in the selected entity id. */
  column?: string;
  /** The label of the column. */
  label?: string;
  /** List of values for the color scale. The number of items must match with the 'colorScale'.
    Example: [0, 3, 6, 9, 10] */
  valueRange?: number[];
};
export type SolventHeatmap = {
  /** List of column configurations. The columns must be available in the selected entity id. */
  columns?: SolventHeatmapColumnConfig[];
  /** The default column from the to use for the solvent heatmap. */
  defaultColumn?: string;
  /** The default specification to use for the solvent heatmap. */
  defaultSpec?: string;
  /** The description of the view. This is used to display additional information about the view in the tooltip. */
  description?: string;
  /** The entity id for the solvent heatmap. */
  entityId?: string;
  /** The icon of the view. Use the font-awesome icon class. For example, 'fas fa-chart-bar'. */
  icon?: string;
  /** The name of the view. This is used to display the view in the workbench. */
  name?: string;
  /** Whether the view is open in the workbench by default. */
  open?: boolean;
  /** List of specification configurations. */
  specs?: SolventHeatmapSpecConfig[];
  /** The type of the view. */
  type?: 'solvent-heatmap';
};
export type NumberFilterBuilder = {
  /** The label of the filter builder. */
  label?: string;
  /** The maximum value to filter. */
  max?: number | number;
  /** The minimum value to filter. */
  min?: number | number;
  /** The step value for the filter. */
  step?: number | number;
  /** The type of the filter builder. */
  type?: 'number';
};
export type SelectFilterBuilder = {
  /** The label of the filter builder. */
  label?: string;
  /** The type of the filter builder. */
  type?: 'select';
};
export type FilterItemWithBuilder = {
  /** The select filter builder configuration. */
  builder?: NumberFilterBuilder | SelectFilterBuilder;
  col: string;
  op: string;
  val: number | number | string | boolean | any[];
};
export type CustomBarChart = {
  /** List of column names to filter. */
  columnFilters?: FilterItemWithBuilder[];
  /** The description of the view. This is used to display additional information about the view in the tooltip. */
  description?: string;
  /** The entity id where the data should be queried from. */
  entityId?: string;
  /** The icon of the view. */
  icon?: string;
  /** The name of the view. */
  name?: string;
  /** Whether the view is open in the workbench by default. */
  open?: boolean;
  /** The type of the view. */
  type?: 'custom-bar-chart';
};
export type Workbench = {
  /** The aggregation configuration. The key is the value of the group and the number is the count of collapsed items in the group. */
  aggregations?: {
    [key: string]: number;
  };
  /** The columns to group by. */
  groupColumns?: string[];
  /** The group sorting criteria for the workbench. */
  groupSortCriteria?: ColumnSorting[];
  /** Enable transition in the chooser if conditions array is not met. */
  matchPreviousSelection?: MatchColumn[];
  /** The maximum number of items that can be selected. Set '-1' to disable the maximum selection. */
  maxSelection?: number;
  /** The minimum number of items that can be selected. */
  minSelection?: number;
  /** Overwrite the presets of the entity column */
  overrideColumns?: object[];
  /** Primary view of the workbench. */
  primaryView?: Ranking | VisynTableView;
  /** The sorting criteria for the workbench. */
  sortCriteria?: ColumnSorting[];
  /** List of available views in the workbench. */
  views?: (
    | GenericView
    | Ranking
    | VisynTableView
    | Visualization
    | CoExpression
    | ExpressionVsCopynumber
    | Oncoprint
    | AzeotropeHeatmap
    | SolventHeatmap
    | CustomBarChart
  )[];
};
export type EntityMeta = {
  /** The color for the entity as HEX value. */
  color?: string;
  /** List of columns for the entity. */
  columns?: (BooleanColumn | CategoricalColumn | LinkColumn | NumericalColumn | SmilesColumn | StringColumn | NumberArrayColumn | StringArrayColumn)[];
  /** Multi-line description of the entity. The description allows [Markdown](https://en.wikipedia.org/wiki/Markdown) which allows you to add links, lists, and other formatting to the description of your entities. New paragraphs are created by adding two line breaks (\n\n). */
  description?: string;
  enableQueryAll?: boolean;
  /** Formatting options for select fields and combo boxes for the entity. */
  formatting?: Formatting;
  globalQuery?: GlobalQuery;
  /** @deprecated Use `visibility` instead. */
  hidden?: boolean;
  /** The icon for the entity. Can be a font-awesome icon name. */
  icon?: string;
  /** The unique identifier of the entity. */
  id?: string;
  /** The name of a single item (e.g., Gene, Cell line). It is pluralized automatically. The default name is `Item`. */
  itemName?: string;
  /** The name of the entity that shown in the user interface. */
  name: string;
  /** Determines the order of entities in the data landscape and the workbench sidebars (column sidebar and view chooser). */
  order?: number;
  predefinedSets?: NamedIdSet[];
  predefined_set_ids?: number[];
  /** The primary idtype of the entity. */
  primaryIdtype?: string;
  proxyViews?: ProxyView[];
  sortingColumnName?: string;
  sortingColumnOrder?: ('ascending' | 'descending') | string;
  /** The name of the table in the database. */
  tableName: string;
  /** Define where the entity should be visible in the Ordino user interface. If unset, the entity is visible everywhere. */
  visibility?: Visibility;
  /** Default workbench configuration for the entity. */
  workbench?: Workbench;
};
export type User = {
  active: boolean;
  email: string;
  first_name?: string;
  id: number;
  last_name?: string;
  username?: string;
};
export type EntityUploadMetadata = {
  changedBy?: User;
  changedOn?: string;
  createdBy?: User;
  createdOn?: string;
  created_at: string;
  defer_length: boolean;
  expires?: string;
  metadata: {
    [key: string]: string;
  };
  offset?: number;
  size: number;
  uid: string;
  upload_chunk_size?: number;
  upload_part?: number;
};
export type UploadedEntityMeta = {
  /** The user who last changed the entity. */
  changedBy?: User;
  changedOn?: string;
  /** List of collaborators. Only used for uploaded entities. */
  collabs?: User[];
  /** The color for the entity as HEX value. */
  color?: string;
  /** List of columns for the entity. */
  columns?: (BooleanColumn | CategoricalColumn | LinkColumn | NumericalColumn | SmilesColumn | StringColumn | NumberArrayColumn | StringArrayColumn)[];
  /** The user who created the entity. */
  createdBy?: User;
  createdOn?: string;
  /** Multi-line description of the entity. The description allows [Markdown](https://en.wikipedia.org/wiki/Markdown) which allows you to add links, lists, and other formatting to the description of your entities. New paragraphs are created by adding two line breaks (\n\n). */
  description?: string;
  enableQueryAll?: boolean;
  /** @internal Additional metadata for entities of upload datasets. */
  fileMetadata?: EntityUploadMetadata;
  /** Formatting options for select fields and combo boxes for the entity. */
  formatting?: Formatting;
  globalQuery?: GlobalQuery;
  /** @deprecated Use `visibility` instead. */
  hidden?: boolean;
  /** The icon for the entity. Can be a font-awesome icon name. */
  icon?: string;
  /** The unique identifier of the entity. */
  id?: string;
  /** @internal Whether the entity is deleted. */
  isDeleted?: boolean;
  /** @internal Whether the entity is uploaded to the database. */
  isUploaded?: boolean;
  /** The name of a single item (e.g., Gene, Cell line). It is pluralized automatically. The default name is `Item`. */
  itemName?: string;
  /** The name of the entity that shown in the user interface. */
  name: string;
  /** Determines the order of entities in the data landscape and the workbench sidebars (column sidebar and view chooser). */
  order?: number;
  predefinedSets?: NamedIdSet[];
  predefined_set_ids?: number[];
  /** The primary idtype of the entity. */
  primaryIdtype?: string;
  proxyViews?: ProxyView[];
  public?: boolean;
  /** List of sheet names for uploaded file. */
  sheetNames?: string[];
  sortingColumnName?: string;
  sortingColumnOrder?: ('ascending' | 'descending') | string;
  /** The name of the table in the database. */
  tableName: string;
  /** Define where the entity should be visible in the Ordino user interface. If unset, the entity is visible everywhere. */
  visibility?: Visibility;
  /** Default workbench configuration for the entity. */
  workbench?: Workbench;
};
export type EntityCountArgs = {
  /** List of entity ids */
  ids?: string[];
};
export type DataTypeEntityDataAvailabilityResponse = {
  /** Entity ID of the currently selected entity. For example: `tdp_cellline` */
  entityId?: string;
  /** The value of the entity whose score is being added. For example: `22Rv1` (cell line name). */
  entityValue?: string;
  /** True if the score column has data for the selected entity. */
  hasData?: boolean;
  /** The column of the score table. For example: `tpm`. */
  scoreColumn?: string;
  /** The entity ID of the score table. For example: `tdp_expression`. */
  scoreEntityId?: string;
};
export type DataTypeEntityDataAvailability = {
  /** The column name of the entity table. For example: `cell_line_name`. */
  entityColumnName?: string;
  /** The value of the entity whose score is being added. For example: `22Rv1` (cell line name). */
  entityValue?: string;
  /** The column of the score table. For example: `tpm`. */
  scoreColumn?: string;
  /** The entity ID of the score table. For example: `tdp_expression`. */
  scoreEntityId?: string;
};
export type CountArgs = {
  filter?: FilterItem[];
  globalQuery?: GlobalQuery;
  namedIdSetId?: number;
};
export type MappingIds = {
  ids?: string[];
};
export type IdKeyFilter = {
  filterColumn?: string;
  id: string;
  idtype?: string;
  key: string;
};
export type DataArgs = {
  columns?: string[];
  filter?: FilterItem[];
  globalQuery?: GlobalQuery;
  mapping?: MappingIds;
  namedIdSetId?: number;
  relationType?: string;
  selectedValues?: string[];
  source?: IdKeyFilter;
  target?: IdKeyFilter;
};
export type IdType = {
  /** Use this to specify a campaign type that can be inferred from this idtype. If a campaign type is defined, any workbench that contains this idtype can be used to add candidates to existing/active campaigns. */
  campaignType?: CampaignType;
  icon?: string;
  id: string;
  label: string;
  viewGroups?: ViewGroup[];
};
export type CampaignableArgs = {
  /** Use this to specify a campaign type that can be inferred from this idtype. If a campaign type is defined, any workbench that contains this idtype can be used to add candidates to existing/active campaigns. */
  campaignType?: string;
};
export type EntityArgs = {
  columns?: string[];
  computeMetrics?: boolean;
  connectorType?: any;
  /** Set to true to receive available categories for each categorical column, otherwise categories are empty. */
  fetchCategories?: boolean;
  filter?: FilterItem[];
  globalQuery?: GlobalQuery;
  namedIdSetId?: number;
};
export type SparseColumn = {
  categoryKey: string;
  categoryValue: string;
  entityId: string;
};
export type SearchArgs = {
  fullMatch: boolean;
  globalQuery?: GlobalQuery;
  namedIdSetId?: number;
  page?: number;
  pageSize?: number;
  searchString: string | string[];
  selectColumns?: string[];
  sparseColumn?: SparseColumn;
  whereColumns?: string[];
};
export type ScoreRow = {
  id: string;
  score?: any;
};
export type EntityMappingDataArgs = {
  columns: string[];
  filter?: FilterItem[];
  globalQuery?: GlobalQuery;
  ids?: string[];
};
export type NamedIdSetFilterArgs = {
  entityId?: string;
  globalQueryAppliedCategories?: Category[];
  globalQueryName?: string;
  name?: string;
  public?: boolean;
  showCurrentUserOnly?: boolean;
  source?: 'config' | 'data_table' | 'user';
};
export type NamedIdSetCreateArgs = {
  collabs?: number[];
  description?: string;
  entityId: string;
  globalQueryAppliedCategories?: Category[];
  globalQueryName?: string;
  idSet: string[];
  name: string;
  public: boolean;
};
export type NamedIdSetUpdateArgs = {
  collabs?: number[];
  description?: string;
  globalQueryAppliedCategories?: Category[];
  globalQueryName?: string;
  idSet?: string[];
  name?: string;
  public?: boolean;
};
export type RelationEntityColumn = {
  /** Specify one or more categorical column(s) that will be used to filter the mapped values. */
  categoryKeys?: string[];
  /** Filter to apply to the relation in addition to the key. */
  filterColumn?: string;
  id: string;
  idtype?: string;
  key: string;
};
export type RelationOneOne = {
  bidirectional?: boolean;
  label?: string;
  /** Determines the order of relations in the workbench sidebars (column sidebar and view chooser). This property controls ordering within the dataset groups. */
  order?: number;
  source: RelationEntityColumn;
  target: RelationEntityColumn;
  type: '1-1';
  workbench?: Workbench;
};
export type RelationOneN = {
  bidirectional?: boolean;
  label?: string;
  /** Determines the order of relations in the workbench sidebars (column sidebar and view chooser). This property controls ordering within the dataset groups. */
  order?: number;
  source: RelationEntityColumn;
  target: RelationEntityColumn;
  type: '1-n';
  workbench?: Workbench;
};
export type MappingMn = {
  connectorType?: string;
  entity: string;
  name?: string;
  order?: number;
  sourceKey: string;
  targetKey: string;
};
export type RelationMn = {
  bidirectional?: boolean;
  label?: string;
  mapping: MappingMn[];
  /** Determines the order of relations in the workbench sidebars (column sidebar and view chooser). This property controls ordering within the dataset groups. */
  order?: number;
  source: RelationEntityColumn;
  target: RelationEntityColumn;
  type: 'm-n';
  workbench?: Workbench;
};
export type RelationOneNSelection = {
  bidirectional?: boolean;
  label?: string;
  /** Determines the order of relations in the workbench sidebars (column sidebar and view chooser). This property controls ordering within the dataset groups. */
  order?: number;
  source: RelationEntityColumn;
  target: RelationEntityColumn;
  type: '1-n-selection';
  workbench?: Workbench;
};
export type RelationMnSelection = {
  bidirectional?: boolean;
  label?: string;
  mapping: MappingMn[];
  /** Determines the order of relations in the workbench sidebars (column sidebar and view chooser). This property controls ordering within the dataset groups. */
  order?: number;
  source: RelationEntityColumn;
  target: RelationEntityColumn;
  type: 'm-n-selection';
  workbench?: Workbench;
};
export type MappingColumn = {
  columnName: string;
  label: string;
  order?: number;
  show?: string;
  type?: string;
};
export type MappingDrilldown = {
  /** Specify the categorical column that will be used when loading a drilldown score. */
  categoryKey?: string;
  columns?: MappingColumn[];
  connectorType?: string;
  entity: string;
  name?: string;
  order?: number;
  /** Pre-configure categories the score column will be filtered by for selected columns. Format is: [score column name]: [category]. */
  preconfiguredCategoryKeys?: {
    [key: string]: string;
  };
  sourceKey: string;
  targetKey: string;
};
export type DrilldownWorkbench = {
  /** The aggregation configuration. The key is the value of the group and the number is the count of collapsed items in the group. */
  aggregations?: {
    [key: string]: number;
  };
  /** The columns to group by. */
  groupColumns?: string[];
  /** The group sorting criteria for the workbench. */
  groupSortCriteria?: ColumnSorting[];
  /** Enable transition in the chooser if conditions array is not met. */
  matchPreviousSelection?: MatchColumn[];
  /** The maximum number of items that can be selected. Set '-1' to disable the maximum selection. */
  maxSelection?: number;
  minSelection?: number;
  /** Overwrite the presets of the entity column */
  overrideColumns?: object[];
  /** Primary view of the workbench. */
  primaryView?: Ranking | VisynTableView;
  /** The sorting criteria for the workbench. */
  sortCriteria?: ColumnSorting[];
  /** List of available views in the workbench. */
  views?: (
    | GenericView
    | Ranking
    | VisynTableView
    | Visualization
    | CoExpression
    | ExpressionVsCopynumber
    | Oncoprint
    | AzeotropeHeatmap
    | SolventHeatmap
    | CustomBarChart
  )[];
};
export type RelationDrilldown = {
  bidirectional?: boolean;
  label?: string;
  mapping: MappingDrilldown[];
  /** Determines the order of relations in the workbench sidebars (column sidebar and view chooser). This property controls ordering within the dataset groups. */
  order?: number;
  /** Use to specify which score types are shown to the user. By default, both single and aggregated score types are available. */
  scoreType?: ('single' | 'aggregated')[];
  source: RelationEntityColumn;
  target: RelationEntityColumn;
  type: 'ordino-drilldown';
  workbench?: DrilldownWorkbench;
};
export type RelationsArgs = {
  source?: string;
  target?: string;
  type?: string;
};
export type IdKey = {
  id: string;
  idtype?: string;
  key: string;
};
export type MappingRelationArgs = {
  aggregationMetricType?: 'MIN' | 'MAX' | 'AVG' | 'SUM' | 'COUNT_DISTINCT' | 'DISTINCT' | 'MEDIAN' | 'FREQUENCY' | 'COUNT' | 'NUMBERS' | 'BOXPLOT';
  aggregationParam?: FilterItem;
  categoryKey?: string;
  categoryValue?: string;
  filter?: FilterItem[];
  mappingEntity: string;
  scoreColumns?: string[];
  source: IdKey;
  sourceIdValues: string[];
  target: IdKey;
  type?: string;
};
export const {
  useGetApiV1ReprovisynAppConfigBannerQuery,
  useLazyGetApiV1ReprovisynAppConfigBannerQuery,
  useGetApiV1ReprovisynAppConfigCustomizationQuery,
  useLazyGetApiV1ReprovisynAppConfigCustomizationQuery,
  useGetApiV1ReprovisynAppConfigUiQuery,
  useLazyGetApiV1ReprovisynAppConfigUiQuery,
  useGetApiV1ReprovisynAppConfigUploadConfigQuery,
  useLazyGetApiV1ReprovisynAppConfigUploadConfigQuery,
  useGetApiV1ReprovisynAppConfigVersionQuery,
  useLazyGetApiV1ReprovisynAppConfigVersionQuery,
  useGetApiV1ReprovisynCampaignTypesQuery,
  useLazyGetApiV1ReprovisynCampaignTypesQuery,
  useGetApiV1ReprovisynClearCacheQuery,
  useLazyGetApiV1ReprovisynClearCacheQuery,
  useGetApiV1ReprovisynEntitiesQuery,
  useLazyGetApiV1ReprovisynEntitiesQuery,
  usePostApiV1ReprovisynEntitiesCountMultiQuery,
  useLazyPostApiV1ReprovisynEntitiesCountMultiQuery,
  usePostApiV1ReprovisynEntitiesByEntityIdCheckDataAvailabilityMutation,
  useGetApiV1ReprovisynEntitiesByEntityIdCountQuery,
  useLazyGetApiV1ReprovisynEntitiesByEntityIdCountQuery,
  usePostApiV1ReprovisynEntitiesByEntityIdCountQuery,
  useLazyPostApiV1ReprovisynEntitiesByEntityIdCountQuery,
  useGetApiV1ReprovisynEntitiesByEntityIdDataQuery,
  useLazyGetApiV1ReprovisynEntitiesByEntityIdDataQuery,
  usePostApiV1ReprovisynEntitiesByEntityIdDataQuery,
  useLazyPostApiV1ReprovisynEntitiesByEntityIdDataQuery,
  usePostApiV1ReprovisynEntitiesByEntityIdIsCampaignableQuery,
  useLazyPostApiV1ReprovisynEntitiesByEntityIdIsCampaignableQuery,
  useGetApiV1ReprovisynEntitiesByEntityIdMetadataQuery,
  useLazyGetApiV1ReprovisynEntitiesByEntityIdMetadataQuery,
  usePostApiV1ReprovisynEntitiesByEntityIdMetadataQuery,
  useLazyPostApiV1ReprovisynEntitiesByEntityIdMetadataQuery,
  usePostApiV1ReprovisynEntitiesByEntityIdSearchQuery,
  useLazyPostApiV1ReprovisynEntitiesByEntityIdSearchQuery,
  usePostApiV1ReprovisynEntityMappingScoreByFromIdAndToIdQuery,
  useLazyPostApiV1ReprovisynEntityMappingScoreByFromIdAndToIdQuery,
  useGetApiV1ReprovisynEntityMappingsByFromIdQuery,
  useLazyGetApiV1ReprovisynEntityMappingsByFromIdQuery,
  usePostApiV1ReprovisynEntityMappingsMultiQuery,
  useLazyPostApiV1ReprovisynEntityMappingsMultiQuery,
  useGetApiV1ReprovisynFileByUuidMetadataQuery,
  useLazyGetApiV1ReprovisynFileByUuidMetadataQuery,
  useGetApiV1ReprovisynIdtypeMappingsQuery,
  useLazyGetApiV1ReprovisynIdtypeMappingsQuery,
  useGetApiV1ReprovisynIdtypeMappingsByFromIdQuery,
  useLazyGetApiV1ReprovisynIdtypeMappingsByFromIdQuery,
  usePostApiV1ReprovisynIdtypeMappingsByFromIdAndToIdQuery,
  useLazyPostApiV1ReprovisynIdtypeMappingsByFromIdAndToIdQuery,
  useGetApiV1ReprovisynIdtypesQuery,
  useLazyGetApiV1ReprovisynIdtypesQuery,
  useGetApiV1ReprovisynNamedIdSetsQuery,
  useLazyGetApiV1ReprovisynNamedIdSetsQuery,
  usePostApiV1ReprovisynNamedIdSetsQuery,
  useLazyPostApiV1ReprovisynNamedIdSetsQuery,
  usePostApiV1ReprovisynNamedIdSetsCreateMutation,
  useDeleteApiV1ReprovisynNamedIdSetsBySetIdMutation,
  usePutApiV1ReprovisynNamedIdSetsBySetIdMutation,
  useGetApiV1ReprovisynNamedIdSetsBySetIdDataQuery,
  useLazyGetApiV1ReprovisynNamedIdSetsBySetIdDataQuery,
  useGetApiV1ReprovisynNamedIdSetsBySetIdMetadataQuery,
  useLazyGetApiV1ReprovisynNamedIdSetsBySetIdMetadataQuery,
  useGetApiV1ReprovisynProxyViewsQuery,
  useLazyGetApiV1ReprovisynProxyViewsQuery,
  useGetApiV1ReprovisynRelationsQuery,
  useLazyGetApiV1ReprovisynRelationsQuery,
  usePostApiV1ReprovisynRelationsQuery,
  useLazyPostApiV1ReprovisynRelationsQuery,
  usePostApiV1ReprovisynRelationsDataQuery,
  useLazyPostApiV1ReprovisynRelationsDataQuery,
  useGetApiV1ReprovisynTestDatabaseConnectionsQuery,
  useLazyGetApiV1ReprovisynTestDatabaseConnectionsQuery,
} = injectedRtkApi;
