import React from 'react';

import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons/faCircleExclamation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Anchor, Group, Stack, Text } from '@mantine/core';
import { i18n } from 'visyn_core/i18n';

import { useAppSelector } from '../../hooks';

/**
 * Show a warning if the license expires in less than 14 days
 */
const GRACE_PERIOD_DAYS = 14;

/**
 * Show relative date format if the license expires in less than 30 days
 */
const USE_RELATIVE_DAY_FORMAT_THRESHOLD = 30;

export function AboutDialogLicenseSection() {
  const appVersionInfo = useAppSelector((state) => state.app.version);
  return appVersionInfo ? (
    <Stack align="flex-start" data-testid="ordino-about-dialog-license-section">
      {appVersionInfo.isTrial === false ? (
        <>
          <Group style={{ gap: '4px' }}>
            <Text fw={700} c="dimmed">
              {i18n.t('reprovisyn:license.licensee')}
            </Text>
            <Text data-testid="licensee-name">{appVersionInfo.company}</Text>
          </Group>
          {appVersionInfo.daysLeft - GRACE_PERIOD_DAYS > 0 ? (
            <Text data-testid="license-period-text">
              {appVersionInfo.daysLeft < USE_RELATIVE_DAY_FORMAT_THRESHOLD
                ? i18n.t('reprovisyn:license.licensePeriodInfoRelative', { count: appVersionInfo.daysLeft })
                : i18n.t('reprovisyn:license.licensePeriodInfoAbsolute', { date: new Date(appVersionInfo.expiryDate).toDateString() })}
            </Text>
          ) : (
            <>
              <Group color="red" style={{ gap: '4px' }}>
                <Text c="red">
                  <FontAwesomeIcon icon={faCircleExclamation} />
                </Text>
                <Text data-testid="license-expired-text" color="red">
                  {i18n.t('reprovisyn:license.licenseEndInfo')}
                </Text>
              </Group>
              <Group gap={0} style={{ gap: '4px' }}>
                <Text> {i18n.t('reprovisyn:license.please')}</Text>
                <Anchor fw={700} variant="light" href="/#/help/contact-us" target="_blank">
                  {i18n.t('reprovisyn:license.contactDatavisyn')}
                </Anchor>
                <Text> {i18n.t('reprovisyn:license.renewLicense')}</Text>
              </Group>
            </>
          )}
        </>
      ) : (
        <>
          <Group style={{ gap: '4px' }}>
            <Text fw={600} c="dimmed">
              {i18n.t('reprovisyn:license.trialFor')}
            </Text>
            <Text data-testid="trial-holder-name">{appVersionInfo.company}</Text>
          </Group>

          {appVersionInfo.daysLeft > 0 ? (
            <Text data-testid="trial-period-text">
              {i18n.t('reprovisyn:license.trialPeriodInfoLong', {
                count: appVersionInfo.daysLeft,
              })}
            </Text>
          ) : (
            <>
              <Group color="red" style={{ gap: '4px' }}>
                <Text c="red">
                  <FontAwesomeIcon icon={faCircleExclamation} />
                </Text>
                <Text c="red" data-testid="trial-expired-text">
                  {i18n.t('reprovisyn:license.trialPeriodEndInfo')}
                </Text>
              </Group>
              <Group gap={0} style={{ gap: '4px' }}>
                <Text> {i18n.t('reprovisyn:license.please')}</Text>
                <Anchor fw={700} variant="light" href="/#/help/contact-us" target="_blank">
                  {i18n.t('reprovisyn:license.contactDatavisyn')}
                </Anchor>
                <Text> {i18n.t('reprovisyn:license.trialPeriodEndContactPredicate')}</Text>
              </Group>
            </>
          )}
        </>
      )}
    </Stack>
  ) : null;
}
