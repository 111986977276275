import type { PayloadAction } from '@reduxjs/toolkit';
import type { IColumnDesc } from 'lineupjs';
import cloneDeep from 'lodash/cloneDeep';
import type { BaseVisConfig } from 'visyn_core/vis';
import { isBarConfig, isCorrelationConfig, isHeatmapConfig, isHexbinConfig, isSankeyConfig, isScatterConfig, isViolinConfig } from 'visyn_core/vis';

import type { IOrdinoAppState, IWorkbenchView } from './interfaces';
import { EWorkbenchView } from './interfaces';

function findViewIndex(state: IOrdinoAppState, workbenchIndex: number, viewId: string) {
  return state.workbenches[workbenchIndex]?.views.findIndex((v) => v.uniqueId === viewId) ?? -1;
}

export function patchVisViewColumnIds(
  columnDesc: (IColumnDesc & {
    [key: string]: any;
  })[],
  view: IWorkbenchView,
): IWorkbenchView {
  if (view.type !== EWorkbenchView.Visualization) {
    return view;
  }
  const newView = cloneDeep(view);
  function updateSelectedColumns(config: BaseVisConfig, columnKey: string) {
    if (!config[columnKey]) {
      return; // no config found for the selected column key -> skip
    }
    const selected: { id: string; name: string; desc: string }[] = Array.isArray(config[columnKey]) ? config[columnKey] : [config[columnKey]];
    const visColumns = selected.map((c) => {
      const col = columnDesc.find((d) => d.column === c?.name || d.uniqueId === c.id);
      if (!col) {
        console.error(`Selected column ${c.name} not found in workbench columns`);
        return null;
      }
      return { id: col.uniqueId, name: col.label || col.column, description: col.description };
    });
    const patchedColumns = Array.isArray(config[columnKey]) ? visColumns.filter((v) => v) : visColumns?.[0];
    newView.parameters.visConfig[columnKey] = patchedColumns;
  }

  const config = view.parameters.visConfig;
  const typeConfigurations = [
    { predicate: isScatterConfig, keys: ['numColumnsSelected', 'facets', 'shape', 'color', 'labelColumns'] },
    { predicate: isCorrelationConfig, keys: ['numColumnsSelected'] },
    { predicate: isSankeyConfig, keys: ['catColumnsSelected'] },
    { predicate: isHeatmapConfig, keys: ['catColumnsSelected', 'color', 'aggregateColumn'] },
    { predicate: isBarConfig, keys: ['catColumnSelected', 'facets', 'group', 'numColumnsSelected', 'aggregateColumn'] },
    { predicate: isHexbinConfig, keys: ['numColumnsSelected', 'color'] },
    { predicate: isViolinConfig, keys: ['numColumnsSelected', 'catColumnSelected', 'subCategorySelected', 'facetBy'] },
  ];
  const typeConfiguration = typeConfigurations.find(({ predicate }) => predicate(config));
  if (typeConfiguration) {
    typeConfiguration.keys.forEach((key) => {
      updateSelectedColumns(config, key);
    });
  }

  return newView;
}

export const viewsReducers = {
  addView(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; view: IWorkbenchView }>) {
    const workbench = state.workbenches[action.payload.workbenchIndex]!;
    const { view } = action.payload;
    if (view.type === EWorkbenchView.Visualization && !view.parameters.visConfig) {
      view.parameters.visConfig = workbench?.initialVisConfig;
    }
    const updatedView = patchVisViewColumnIds(workbench.columnDescs, action.payload.view);

    if (view.id === workbench.primaryView.id) {
      workbench.primaryView.open = true;
    } else {
      workbench?.views.push(updatedView);
    }

    if (state.focusWorkbenchIndex === action.payload.workbenchIndex) {
      state.midTransition = false;
    }
  },
  setViewParameters(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; viewId: string; parameters: any }>) {
    Object.keys(action.payload.parameters).forEach((p) => {
      const workbench = state.workbenches[action.payload.workbenchIndex]!;
      if (workbench) {
        if (action.payload.viewId === workbench.primaryView.uniqueId) {
          workbench.primaryView.parameters[p] = action.payload.parameters[p];
        } else {
          const viewIndex = findViewIndex(state, action.payload.workbenchIndex, action.payload.viewId);
          if (viewIndex >= 0) {
            workbench.views[viewIndex]!.parameters[p] = action.payload.parameters[p];
          }
        }
      }
    });
  },
  removeView(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; viewId: string }>) {
    const workbench = state.workbenches[action.payload.workbenchIndex];
    if (action.payload.viewId === workbench!.primaryView.uniqueId) {
      state.workbenches[action.payload.workbenchIndex]!.primaryView.open = false;
    } else {
      const viewIndex = findViewIndex(state, action.payload.workbenchIndex, action.payload.viewId);
      if (viewIndex >= 0) {
        state.workbenches[action.payload.workbenchIndex]?.views.splice(viewIndex, 1);
        const primaryViewFilters = state.workbenches[action.payload.workbenchIndex]?.primaryView.filters || [];
        const viewFilters = state.workbenches[action.payload.workbenchIndex]?.views.map((view) => view.filters).flat() || [];
        const filterLength = Array.from(new Set<string>([...viewFilters, ...primaryViewFilters])).length;
        state.workbenches[action.payload.workbenchIndex]!.filterLength = filterLength;
      }
    }
  },

  addFilter(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; viewId: string; filter: string[] }>) {
    const workbench = state.workbenches[action.payload.workbenchIndex]!;
    const isPrimaryView = action.payload.viewId === workbench.primaryView.uniqueId;

    if (isPrimaryView) {
      workbench.primaryView.filters = action.payload.filter;
    } else {
      const viewIndex = findViewIndex(state, action.payload.workbenchIndex, action.payload.viewId);
      workbench.views[viewIndex]!.filters = action.payload.filter;
    }
    const primaryViewFilters = workbench?.primaryView.filters || [];
    const viewFilters = workbench?.views.map((view) => view.filters).flat() || [];
    const filterLength = Array.from(new Set<string>([...viewFilters, ...primaryViewFilters])).length;
    workbench.filterLength = filterLength;
  },

  removeAllFilters(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number }>) {
    const workbench = state.workbenches[action.payload.workbenchIndex]!;
    if (workbench.primaryView) {
      workbench.primaryView.filters = [];
    }
    workbench.views.forEach((view) => {
      view.filters = [];
    });
    workbench.filterLength = 0;
  },
};
