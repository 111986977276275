import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { insightApi, insightSlice } from 'aevidence/insight/store';

import { appReducer } from './appSlice';
import { INITIAL_STATE } from './configurePersist/constants';
import { ordinoMigrations } from './configurePersist/ordinoMigrations';
import { ordinoStateReconciler } from './configurePersist/ordinoStateReconciler';
import { tinybasePersistStorage } from './configurePersist/tinybasePersistStorage';
import { workbenchesPersistTransform } from './configurePersist/workbenchPersistTransform';
import { addApiEnhancements } from './enhanceApi';
import { addVisynApiEnhancements } from './enhanceVisynApi';
import type { IOrdinoAppState } from './interfaces';
import { menuReducer } from './menuSlice';
import { ordinoReducer } from './ordinoSlice';
import { createMigrate } from './persist/createMigrate';
import { persistReducer } from './persist/persistReducer';
import { persistStore } from './persist/persistStore';
import type { EntityMeta, UploadedEntityMeta } from './reprovisynApi';
import { reprovisynApi } from './reprovisynApi';
import { visynApi } from './visynApi';

// export from visyn package all of the visyn reducers that are needed then spread them here. "createVisionReducers"
export const allReducers = combineReducers({
  ordino: persistReducer(
    {
      key: 'ordino',
      version: 6,
      migrate: createMigrate(ordinoMigrations, { debug: false }),
      stateReconciler: ordinoStateReconciler,
      blacklist: ['isAnimating', Object.keys(INITIAL_STATE), 'currentSessionId'] as Array<keyof IOrdinoAppState>,
      storage: tinybasePersistStorage,
      transforms: [workbenchesPersistTransform],
    },
    ordinoReducer,
  ),
  menu: menuReducer,
  app: appReducer,
  [insightApi.reducerPath]: insightApi.reducer,
  [insightSlice.name]: insightSlice.reducer,
  [visynApi.reducerPath]: visynApi.reducer,
  [reprovisynApi.reducerPath]: reprovisynApi.reducer,
});
// add api enhancements before configuring the store
addApiEnhancements();
addVisynApiEnhancements();

export function isUploadedEntityMeta(entityMeta?: EntityMeta | UploadedEntityMeta): entityMeta is UploadedEntityMeta {
  return !!(entityMeta && 'isUploaded' in entityMeta && entityMeta.isUploaded);
}

export const store = configureStore({
  reducer: allReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      /**
       * The check has been deactivated as it dramatically impacts performance during session transitions, even in development mode.
       */
      immutableCheck: false,
      serializableCheck: false,
    })
      .concat(visynApi.middleware)
      .concat(insightApi.middleware)
      .concat(reprovisynApi.middleware),
});

export const persistor = persistStore(store, { manualPersist: true }, () => {});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
