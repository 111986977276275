import * as React from 'react';

import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Text } from '@mantine/core';

export function InfoMessage({ info, dataTestId = 'ordino-info-message' }: { info: string | null; dataTestId?: string }) {
  return info ? (
    <Alert px="md" icon={<FontAwesomeIcon icon={faInfoCircle} />} color="dvPrimary" data-testid={dataTestId}>
      <Text>{info}</Text>
    </Alert>
  ) : null;
}
