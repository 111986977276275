import type { ILocaleEPDesc, IRegistry } from 'visyn_core/plugin';
import { EP_PHOVEA_CORE_LOCALE, PluginRegistry } from 'visyn_core/plugin';

export default function (registry: IRegistry) {
  registry.push(
    EP_PHOVEA_CORE_LOCALE,
    'tdpCoreLocaleEN',
    function () {
      return import('./locales/en/tdp.json').then(PluginRegistry.getInstance().asResource);
    },
    <ILocaleEPDesc>{
      ns: 'tdp',
    },
  );

  /* phovea_clue */
  registry.push(
    EP_PHOVEA_CORE_LOCALE,
    'phoveaClueLocaleEN',
    function () {
      return import('./locales/en/phovea.json').then(PluginRegistry.getInstance().asResource);
    },
    <ILocaleEPDesc>{
      ns: 'phovea',
    },
  );
}
