import * as ComLink from 'comlink';
import type { IRow } from 'visyn_core/base';

import type { IOrdinoColumnDesc } from '../types/column';

export function toWorkbenchData({ rawData, columns }: { rawData: IRow[]; columns: IOrdinoColumnDesc[] }) {
  const fullData = [] as IRow[];
  const dataMap: Record<string, IRow> = {};
  const initialColumns = columns.filter((c) => c.initialRanking).map((c) => ({ ...c }));
  rawData.forEach((row) => {
    if (!dataMap[row.id]) {
      dataMap[row.id] = row;
      fullData.push(row);
    }
  });
  initialColumns.forEach((column) => {
    column.dataMap = {};

    // Create the array in one go using map
    fullData.map((row) => {
      const value = { id: row.id, val: row[column.column] };
      column.dataMap![row.id] = value;
      return value;
    });
  });
  const columnData = Object.fromEntries(initialColumns.map((c) => [c.uniqueId, c.dataMap]));
  return {
    fullData,
    dataMap,
    columnData,
  };
}

ComLink.expose({ toWorkbenchData });
