import isEqual from 'lodash/isEqual';
import type { IPluginDesc } from 'visyn_core/plugin';

import type { ISelection, IViewPluginDesc } from '../base/interfaces';

export class ViewUtils {
  static toViewPluginDesc<ReturnType extends IViewPluginDesc = IViewPluginDesc>(p: IPluginDesc): ReturnType {
    const r: any = p;
    r.selection = r.selection || 'none';
    r.group = { name: 'Other', order: 99, ...r.group };
    r.securityNotAllowedText = r.securityNotAllowedText != null ? r.securityNotAllowedText : false;

    // common typo
    if (r.idType !== undefined) {
      r.idtype = r.idType;
    }
    return r;
  }

  /**
   * compares two selections and return true if they are the same
   * @param {ISelection} a
   * @param {ISelection} b
   * @returns {boolean}
   */
  static isSameSelection(a: ISelection, b: ISelection): boolean {
    const aNull = a == null || a.idtype == null;
    const bNull = b == null || b.idtype == null;
    if (aNull || bNull) {
      return aNull === bNull;
    }
    const base = a.idtype.id === b.idtype.id && isEqual(a.ids?.slice()?.sort(), b.ids?.slice()?.sort());
    if (!base) {
      return false;
    }
    const aAllSize = a.all ? a.all.size : 0;
    const bAllSize = b.all ? b.all.size : 0;
    if (aAllSize !== bAllSize) {
      return undefined;
    }
    if (aAllSize === 0) {
      return true;
    }
    // same size but not empty check entries
    return Array.from(a.all!.entries()).every(([key, value]) => {
      const other = b.all.get(key);
      if (!other) {
        return false;
      }

      return isEqual(value?.slice()?.sort(), other?.slice()?.sort());
    });
  }
}
