import React from 'react';

import { RingProgress } from '@mantine/core';
import { i18n } from 'visyn_core/i18n';

import type { VersionResponse } from '../../store/reprovisynApi';

export function TrialProgressRing({ appVersionInfo }: { appVersionInfo: VersionResponse }) {
  const scaledTrialProgress = (appVersionInfo.daysLeft / appVersionInfo.totalDays) * 100;
  // add different shades of red for all different quartiles
  const color = scaledTrialProgress < 25 ? 'red.5' : scaledTrialProgress < 50 ? 'red.4' : scaledTrialProgress < 75 ? 'red.3' : 'red.2';
  return (
    <RingProgress
      data-testid="trial-progress-ring"
      sections={[
        {
          value: scaledTrialProgress,
          color,
          tooltip: i18n.t('reprovisyn:license.trialInfoTooltip', { daysLeft: appVersionInfo.daysLeft, totalDays: appVersionInfo.daysLeft }),
        },
      ]}
      rootColor="gray.6"
      size={28}
      mr={1}
      thickness={4}
    />
  );
}
