import isEqual from 'lodash/isEqual';

import { SESSION_URL_PARAM_KEY } from './constants';
import { INITIAL_STATE } from '../../store/configurePersist/constants';
import type { KeyAccessState } from '../../store/persist/types';

/**
 * This function is used to serialize the snapshot of the app for persistence.
 * It takes the snapshot object, converts each value to a JSON string, and then
 * converts the whole snapshot object to a JSON string.
 *
 * @param {object} snapshot - The snapshot of the app.
 * @returns {string} The serialized snapshot.
 *
 * @example
 * const snapshot = {
 *   prop1: 'value1',
 *   prop2: { nestedProp: 'nestedValue' },
 * };
 * const serializedSnapshot = serializeSnapshot(state);
 * console.log(serializedSnapshot);
 * // Output: '{"prop1":"\"value1\"","prop2":"{\"nestedProp\":\"nestedValue\"}"}'
 */
export const serializeSnapshot = (snapshot: object): string => {
  return JSON.stringify(
    Object.entries(snapshot).reduce((acc, [key, value]) => {
      acc[key] = JSON.stringify(value);
      return acc;
    }, {}),
  );
};

/**
 * This function is used to deserialize the persisted snapshot.
 * It takes a JSON string, parses it into an object, then parses each value in the object from a JSON string to its original form.
 *
 * @param {string} persistedSnapshot - The persisted snapshot of the app as a JSON string.
 * @returns {KeyAccessState} The deserialized state.
 *
 * @example
 * const persistedSnapshot = '{"prop1":"\"value1\"","prop2":"{\"nestedProp\":\"nestedValue\"}"}';
 * const snapshot = deserializeSnapshot(persistedSnapshot);
 * console.log(snapshot);
 * // Output: { prop1: 'value1', prop2: { nestedProp: 'nestedValue' } }
 */
export function deserializeSnapshot(persistedSnapshot: string): KeyAccessState {
  const state: KeyAccessState = {};
  Object.entries(JSON.parse(persistedSnapshot)).forEach(([key, value]) => {
    state[key] = JSON.parse(value as string);
  });
  return state;
}

/**
 * Basic check if the snapshot is valid
 */
export const isValidSnapshot = (snapshot: string): boolean => {
  try {
    const deserialized = JSON.parse(snapshot) as Record<string, any>;

    // check if the snapshot is the initial empty state
    const isInitial = isEqual(deserialized, INITIAL_STATE);
    if (isInitial) {
      return true;
    }

    // check if the snapshot has valid workbenches
    const workbenches = JSON.parse(deserialized?.workbenches);
    const hasValidWorkbenches = Array.isArray(workbenches) && workbenches.length > 0;

    return deserialized && hasValidWorkbenches;
  } catch (error) {
    return false;
  }
};

/**
 * This function is used to create a deep link to a session.
 * It takes a UUID and creates a deep link to the session with that UUID.
 *
 * @param {string} uuid - The UUID of the session.
 * @returns {string} The deep link to the session.
 *
 * @example
 * const uuid = '1234-5678-9101';
 * const deepLink = createSessionDeepLink(uuid);
 * console.log(deepLink);
 * // Output: 'https://ordino-enterprise/app/?session=1234-5678-9101'
 */
export const createSessionDeepLink = (uuid: string) => {
  const url = new URL(`${window.location.origin}/app/`);
  const searchKey = SESSION_URL_PARAM_KEY;
  const searchValue = uuid;
  url.searchParams.set(searchKey, searchValue);
  return url.toString();
};
