import type { Column, CompositeColumn, IColumnDump, IDataProviderDump, IDataRow, IRankingDump } from 'lineupjs';
import { ImpositionCompositeColumn, LinkColumn, NumberColumn, NumbersColumn, StackColumn, StringsColumn } from 'lineupjs';
import pick from 'lodash/pick';

import type { AsyncDataProvider } from './AsyncDataProvider';
import { isCombinedColumn } from '../../store/interfaces';
import type { IOrdinoColumnDesc } from '../../store/types/column';

export enum ExportValueFormat {
  JSON = 'json',
  Text = 'text',
}

/**
 * Adds custom suffix to the event type to avoid conflicts with other listeners on the event.
 */
export const workbenchSuffix = (event: string): string => `${event}.workbench`;

/**
 * Generates a unique id for a composite column if not available.
 */
export const compositeColumnIdentifier = (column: IColumnDump | CompositeColumn, provider: AsyncDataProvider) => {
  const desc = provider.fromDescRef(column.desc);

  // if added from the sidebar it will have a uniqueId
  if (desc.uniqueId) {
    return desc.uniqueId;
  }

  // dragged and dropped columns have no uniqueId --> generate one from it's children
  if (isCombinedColumn(desc)) {
    return column?.desc.id;
  }

  console.warn('No id found for column', column); // support columns do not need unique ids
  return null;
};

const computeStackColumn = (column: StackColumn, row: IDataRow) => {
  const w = column.getWidth();
  const children = column.children as NumberColumn[];
  // missing value for the stack column if at least one child value is missing
  if (children.some((d) => d.getRawNumber(row) === null)) {
    return null;
  }
  return children.reduce((acc, d) => acc + d.getRawNumber(row) * (d.getWidth() / w), 0);
};

/**
 * Retrieves the value of a specified column for a given row.
 * If a format is provided, it returns the formatted value. For categorical columns the label is exported instead of the raw value.
 */
export const getColumnValue = (column: Column, row: IDataRow, format: ExportValueFormat | undefined) => {
  if (column instanceof LinkColumn) {
    return column.getLink(row)?.alt;
  }

  if (column instanceof NumberColumn) {
    return column.getRawNumber(row);
  }
  if (column instanceof StackColumn) {
    return computeStackColumn(column, row);
  }

  if (column instanceof ImpositionCompositeColumn) {
    return column.getRawNumber(row);
  }

  if (column instanceof NumbersColumn) {
    return `[${column.getRawNumbers(row).join(',')}]`;
  }

  if (column instanceof StringsColumn) {
    const rawValue = format ? (column.getExportValue(row, format) ?? '').trim() : (column.getValue(row) ?? '');
    // NOTE: @dv-usama-ansari: To escape a " in a CSV, it needs to be preceded by another ", therefore we replace all " with "".
    const sanitizedRawValue = rawValue.replace(/\\"/g, '"').replace(/"/g, '""');

    return `[${sanitizedRawValue}]`;
  }

  return column.getValue(row);
};

/**
 * When any of these props change, the columns are recomputed
 */
export const modifiableColumnProps = ['label', 'summary', 'colorMapping', 'dataMap'] as const;

export type ModifiableColumnProps = Partial<Pick<IOrdinoColumnDesc, (typeof modifiableColumnProps)[number]>>;

export function isModifiableColumnProp(key: string): key is keyof ModifiableColumnProps {
  return modifiableColumnProps.includes(key as keyof ModifiableColumnProps);
}

export function trackedColumnProps(column: IColumnDump, trackedMetadata = modifiableColumnProps) {
  const trackedProps = pick(column, trackedMetadata);
  return { ...column.desc, ...trackedProps };
}

export type FlatDataProviderDump = {
  overrideColumns: IColumnDump[];
  sortCriteria?: IRankingDump['sortCriteria'];
  aggregations?: IDataProviderDump['aggregations'];
  groupColumns?: IRankingDump['groupColumns'];
  groupSortCriteria?: IRankingDump['groupSortCriteria'];
};

/**
 * Checks if the dump is valid by checking if it has at least one column.
 * The sortCriteria, groupColumns, groupSortCriteria and aggregations are not considered if the columns are empty.
 */
export const isValidDump = (dump: IDataProviderDump | undefined): dump is IDataProviderDump => (dump?.rankings?.[0]?.columns?.length ?? 0) > 0;

export function flattenDataProviderDump(dump: IDataProviderDump | undefined): FlatDataProviderDump {
  const ranking = dump?.rankings?.[0] || {};
  return {
    overrideColumns: ranking.columns || [],
    sortCriteria: ranking.sortCriteria,
    aggregations: dump?.aggregations,
    groupColumns: ranking.groupColumns,
    groupSortCriteria: ranking.groupSortCriteria,
  };
}

export function rebuildDataProviderDump({
  overrideColumns,
  aggregations,
  groupColumns,
  groupSortCriteria,
  sortCriteria,
}: FlatDataProviderDump): IDataProviderDump | undefined {
  if (!overrideColumns?.length) {
    return undefined;
  }

  // order of they keys matters here as JSON.stringify is used to compare the dumps
  return {
    $schema: 'https://lineup.js.org/develop/schema.4.0.0.json',
    selection: [],
    aggregations: aggregations ?? {},
    rankings: [
      {
        columns: overrideColumns,
        sortCriteria,
        groupSortCriteria,
        groupColumns,
      },
    ],

    showTopN: 10, // we do not track this in the workbench
  };
}
