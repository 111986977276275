import type { CellSchema, Persister as TinybasePersister, Store as TinybaseStore } from 'tinybase';
import { createRelationships, createStore as createTinybaseStore } from 'tinybase';
import { createIndexedDbPersister } from 'tinybase/persisters/persister-indexed-db';

import type { LocalAnalysisSnapshot } from './analysis-sessions.types';
import { TinybaseStoreKeys } from './analysis-sessions.types';
import type { AnalysisSession } from '../visynApi';

export const analysisSessionStore = createTinybaseStore().setTablesSchema({
  [TinybaseStoreKeys.AnalysisSessions]: {
    id: { type: 'string' },
    name: { type: 'string' },
    changedBy: { type: 'string' },
    changedOn: { type: 'string' },
    createdBy: { type: 'string' },
    createdOn: { type: 'string' },
    lastOpenedOn: { type: 'number' },
    /**
     * This property would infer type `ArrayAsString` in its implementation
     */
    collabs: { type: 'string' },
    description: { type: 'string' },
    public: { type: 'boolean' },
    selectedSnapshot: { type: 'string' }, // currently selected snapshot, readonly
    currentSnapshot: { type: 'string' }, // currently snapshot, currently being edited
    appName: { type: 'string' },
    isSharedSession: { type: 'boolean' },
    visibility: { type: 'string' },
  } as Record<keyof Omit<AnalysisSession, 'currentSnapshot' | 'snapshots'>, CellSchema>,

  [TinybaseStoreKeys.StateSnapshots]: {
    id: { type: 'string' },
    sessionId: { type: 'string' },
    snapshot: { type: 'string' },
    name: { type: 'string' },
    appName: { type: 'string' },
    appVersion: { type: 'string' },
    changedBy: { type: 'string' },
    changedOn: { type: 'string' },
    createdBy: { type: 'string' },
    createdOn: { type: 'string' },
    description: { type: 'string' },
  } as Record<keyof LocalAnalysisSnapshot, CellSchema>,
});

export const relationships = createRelationships(analysisSessionStore);
relationships.setRelationshipDefinition(
  TinybaseStoreKeys.SessionStateSnapshots,
  TinybaseStoreKeys.StateSnapshots,
  TinybaseStoreKeys.AnalysisSessions,
  'sessionId',
);

export const tinybaseIndexedDbPersisterFactory = (store: TinybaseStore) => {
  return createIndexedDbPersister(store, TinybaseStoreKeys.AnalysisSessions);
};

/**
 * This is a global variable to store the persister instance
 * It is used to manually save the session when the state changes
 * The auto-save feature is enabled by default but when the state changes, we also want to save the session to avoid lost updates with the auto-save feature
 */
export const sessionPersister = {
  value: null as TinybasePersister | null,
};

export const tinybasePersisterJob = async (persister: TinybasePersister) => {
  sessionPersister.value = persister;
  await persister.load();
  await persister.startAutoSave();
};
